import { Container, Input, Title, Main, Form, Button, LinkComponent, CustomToast } from '../../components'
import api from '../../api'
import styles from './styles.module.css'
import { useFormWithValidation } from '../../utils'
import { useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import MetaTags from 'react-meta-tags'
import { useTranslation } from 'react-i18next'

const SetNewPassword = ({ history }) => {
  const { values, handleChange, errors, isValid, resetForm } = useFormWithValidation()
  const [ apiError, setApiError ] = useState(false)
  const { t } = useTranslation()

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const [tokenIsValid, setTokenIsValid] = useState(null);
  const [ formErrors, setFormErrors ] = useState({})

  const validateResetPasswordToken = async () => {
    try {
      const data = await api.validateResetPasswordToken({ token });
      setTokenIsValid(data?.is_valid === true);
    } catch (error) {
      setTokenIsValid(false);
      setApiError(true)
    }
  }

  useEffect(() => {
    validateResetPasswordToken()
  }, [token]);

  const setNewPassword = async ({ new_password, new_password_repeat, token }) => {
    try {
      const response = await api.setNewPassword({ new_password, new_password_repeat, token })
      if (response?.success === true) {
        setFormErrors({})
        history.push('/confirm_set_new_password')
      }
    }
    catch (errors) {
      setFormErrors(errors)
    }
  }

  return <Main>
    <CustomToast
      headerText={t('Errors.Error')}
      bodyText={t('Errors.CommonError')}
      onClose={() => setApiError(false)}
      show={apiError}
      delay={3000}
    />
    <Container>
      <MetaTags>
        <title>{t('SetNewPassword.SetNewPassword')}</title>
        <meta name="description" content="Учебная платформа STIA - задать новый пароль" />
        <meta property="og:title" content={t('SetNewPassword.SetNewPassword')} />
      </MetaTags>
      <Title title={t('SetNewPassword.SetNewPassword')} />
      {tokenIsValid && tokenIsValid !== null &&
        <Form
        className={styles.form}
        onSubmit={e => {
          e.preventDefault()
          const valuesWithToken = {...values, token: token}
          setNewPassword(valuesWithToken)
        }}
      >
        <Input
          required
          label={t('ChangePassword.NewPassword')}
          name='new_password'
          type='password'
          onChange={handleChange}
          error={formErrors?.new_password}
          errorText={formErrors?.new_password}
        />
        <Input
          required
          label={t('ChangePassword.NewPasswordRepeat')}
          name='new_password_repeat'
          type='password'
          onChange={handleChange}
          error={formErrors?.new_password_repeat}
          errorText={formErrors?.new_password_repeat}
        />
        <Button
          modifier='style_dark-blue'
          disabled={!isValid}
          type='submit'
          className={styles.button}
        >
          {t('SetNewPassword.ConfirmNewPassword')}
        </Button>
      </Form>
    }
    {!tokenIsValid && tokenIsValid !== null &&
      <>
        <div className={styles.text}>
          {t('SetNewPassword.OldLink')}
        </div>
        <LinkComponent
          href='/reset_password'
          title={t('ResetPassword.ResetPassword')}
          className={styles.menuButton}
        />
      </>
    }
    </Container>
  </Main>
}

export default SetNewPassword

import styles from './style.module.css'
import cn from 'classnames'
import { Button } from '../index'
import { useTranslation } from 'react-i18next'


const StudySessionNavigation = ({ prevLesson, studySession, nextLesson, containerClassName, history, currentLessonType }) => {
  const { t } = useTranslation();

  const prevClickHandler = () => {
    if (prevLesson) {
      const keys = prevLesson.split('.')
      history.push(`/study_sessions/${studySession.id}/${keys[0]}/${keys[1]}?module_id=${keys[2]}`)
      if (currentLessonType === keys[0]) {
        history.go(0)
      }
    }
  }

  const studySessionClickHandler = () => {
    history.push(`/study_sessions/${studySession.id}`)
  }

  const nextClickHandler = () => {
    if (nextLesson) {
      const keys = nextLesson.split('.')
      history.push(`/study_sessions/${studySession.id}/${keys[0]}/${keys[1]}?module_id=${keys[2]}`)
      if (currentLessonType === keys[0]) {
        history.go(0)
      }
    }
  }

  return (
    <div className={cn(styles.container, containerClassName)}>
      {prevLesson && 
        <Button
          clickHandler={prevClickHandler}
          disabled={!prevLesson}
        >
          {t('Lesson.PrevLesson')}
        </Button>
      }
        <Button
          clickHandler={studySessionClickHandler}
          disabled={!studySession}
        >
          {t('Lesson.ToStudySession')}
        </Button>
      {nextLesson && 
        <Button
          clickHandler={nextClickHandler}
          disabled={!nextLesson}
        >
          {t('Lesson.NextLesson')}
        </Button>
      }
    </div>
  )
}

export default StudySessionNavigation

class Api {
  constructor (api_url, headers) {
    this._api_url = api_url
    this._headers = headers
  }

  checkResponse (res) {
    return new Promise((resolve, reject) => {
      if (res.status === 204) {
        return resolve(res)
      }
      else if (res.status === 404 ) {
        document.location.href = '/404'
      }
      else if (res.status === 403 ) {
        document.location.href = '/403'
      }
      else if (res.status === 500 ) {
        document.location.href = '/500'
      }
      else {
        const func = res.status < 400 ? resolve : reject
        res.json().then(data => func(data))
      }
    })
  }

  async signin({ email, password }) {
    try {
      const response = await fetch(this._api_url + '/api/auth/token/login/', {
        method: 'POST',
        headers: this._headers,
        body: JSON.stringify({ email, password })
      });
      return this.checkResponse(response);
    } catch (error) {
      console.error(error);
    }
  }
  
  async signOut() {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(this._api_url + '/api/auth/token/logout/', {
        method: 'POST',
        headers: { ...this._headers, authorization: `Token ${token}` }
      });
      return this.checkResponse(response);
    } catch (error) {
      console.error(error);
    }
  }
  
  async signup({ email, citizenship, first_name, last_name, iin, i_agree, language }) {
    try {
      const response = await fetch(this._api_url + '/api/users/', {
        method: 'POST',
        headers: this._headers,
        body: JSON.stringify({ email, citizenship, first_name, last_name, iin, i_agree, language })
      });
      return this.checkResponse(response);
    } catch (error) {
      console.error(error);
    }
  }

  async confirmEmail({ token }) {
    try {
      const response = await fetch(this._api_url + '/api/confirm_email/', {
        method: 'POST',
        headers: this._headers,
        body: JSON.stringify({ token })
      });
      return this.checkResponse(response);
    } catch (error) {
      console.error(error);
    }
  }
  
  async getUserData() {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(this._api_url + '/api/users/me/', {
        method: 'GET',
        headers: { ...this._headers, authorization: `Token ${token}` }
      });
      return this.checkResponse(response);
    } catch (error) {
      console.error(error);
    }
  }
  
  async changePassword({ current_password, new_password, new_password_repeat }) {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(this._api_url + '/api/users/set_password/', {
        method: 'POST',
        headers: { ...this._headers, authorization: `Token ${token}` },
        body: JSON.stringify({ current_password, new_password, new_password_repeat })
      });
      return this.checkResponse(response);
    } catch (error) {
      console.error(error);
    }
  }
  
  async resetPassword({ email }) {
    try {
      const response = await fetch(this._api_url + '/api/reset_password/', {
        method: 'POST',
        headers: this._headers,
        body: JSON.stringify({ email })
      });
      return this.checkResponse(response);
    } catch (error) {
      console.error(error);
    }
  }  

  async validateResetPasswordToken ({ token }) {
    try {
      const response = await fetch(this._api_url + '/api/validate_reset_password_token/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token })
      });
      return this.checkResponse(response)
    } catch (error) {
      console.error(error);
    }
  };

  async setNewPassword ({ new_password, new_password_repeat, token }) {
    try {
      const response = await fetch(
        this._api_url + '/api/set_new_password/',
        {
          method: 'POST',
          headers: this._headers,
          body: JSON.stringify({
            new_password,
            new_password_repeat,
            token
          })
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }
  
  async getUser () {
    const token = localStorage.getItem('token')
    try {
      const response = await fetch(
        this._api_url + `/api/users/me/`,
        {
          method: 'GET',
          headers: {
            ...this._headers,
            'authorization': `Token ${token}`
          }
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }  

  async updateUser ({
    email, citizenship, iin, first_name, last_name,
    phone, company, position, address, language
  }) {
    try {
      const token = localStorage.getItem('token')
      const response = await fetch(
        this._api_url + '/api/users/me/',
        {
          method: 'PATCH',
          headers: {
            ...this._headers,
            'authorization': `Token ${token}`
          },
          body: JSON.stringify({
            email, citizenship, iin, first_name, last_name,
            phone, company, position, address, language
          })
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async getDocument () {
    try {
      const token = localStorage.getItem('token')
      const response = await fetch(
        this._api_url + `/api/users/document/`,
        {
          method: 'GET',
          headers: {
            ...this._headers,
            'authorization': `Token ${token}`
          }
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async updateDocument ({
    number, birth_date, issuing_authority, expiration_date
  }) {
    try {
      const token = localStorage.getItem('token')
      const response = await fetch(
        this._api_url + '/api/users/document/',
        {
          method: 'PATCH',
          headers: {
            ...this._headers,
            'authorization': `Token ${token}`
          },
          body: JSON.stringify({
            number, birth_date, issuing_authority, expiration_date
          })
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async getCourses({ page, selectedIds, searchTerm, userLanguage }) {
    try {
      const token = localStorage.getItem('token')
      let url = this._api_url + '/api/courses/'
      if (page) {
        url = url + `?page=${page}`
      }
      if (selectedIds) {
        url = url + '&type_id=' + selectedIds.join(',')
      }
      if (searchTerm) {
        url = url + '&name=' + searchTerm
      }
      const response = await fetch(
        url,
        {
          method: 'GET',
          headers: {
            ...this._headers,
            ...(token && {'authorization': `Token ${token}`}),
            ...{'user-lang': userLanguage}
          },
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async getCourseTypes({ userLanguage }) {
    try {
      const token = localStorage.getItem('token')
      const response = await fetch(
        this._api_url + '/api/course_types/',
        {
          method: 'GET',
          headers: {
            ...this._headers,
            ...(token && {'authorization': `Token ${token}`}),
            ...{'user-lang': userLanguage}
          }
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async getCourse( {id, userLanguage } ) {
    try {
      const token = localStorage.getItem('token')
      const response = await fetch(
        this._api_url + `/api/courses/${id}/`,
        {
          method: 'GET',
          headers: {
            ...this._headers,
            ...(token && {'authorization': `Token ${token}`}),
            ...{'user-lang': userLanguage}
          }
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async createCourseRequest({ course }) {
    try {
      const token = localStorage.getItem('token')
      const response = await fetch(
        this._api_url + '/api/course_requests/',
        {
          method: 'POST',
          headers: {
            ...this._headers,
            'authorization': `Token ${token}`
          },
          body: JSON.stringify({
            course
          })
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async getCourseRequests({ courseRequestsPage }) {
    try {
      const token = localStorage.getItem('token')
      const response = await fetch(
        this._api_url + `/api/course_requests/?page=${courseRequestsPage}`,
        {
          method: 'GET',
          headers: {
            ...this._headers,
            'authorization': `Token ${token}`
          }
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async getStudySessions({ studySessionsPage }) {
    try {
      const token = localStorage.getItem('token')
      const response = await fetch(
        this._api_url + `/api/study_sessions/?page=${studySessionsPage}`,
          {
            method: 'GET',
            headers: {
              ...this._headers,
              'authorization': `Token ${token}`
            },
          }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async getStudySession( {id} ) {
    try {
      const token = localStorage.getItem('token')
      const response = await fetch(
        this._api_url + `/api/study_sessions/${id}/`,
        {
          method: 'GET',
          headers: {
            ...this._headers,
            'authorization': `Token ${token}`
          },
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async getLessonNeighbours ({study_session_id, lesson_id, lesson_type, module_id}) {
    try {
      const token = localStorage.getItem('token')
      const response = await fetch(
        this._api_url + `/api/${lesson_type}/${lesson_id}/get_neighbours/?study_session_id=${study_session_id}&module_id=${module_id}`,
        {
          method: 'GET',
          headers: {
            ...this._headers,
            'authorization': `Token ${token}`
          },
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async getVideoLesson( {id} ) {
    try {
      const token = localStorage.getItem('token')
      const response = await fetch(
        this._api_url + `/api/video_lessons/${id}/`,
        {
          method: 'GET',
          headers: {
            ...this._headers,
            'authorization': `Token ${token}`
          },
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async completeVideoLesson ( {study_session_id, id} ) {
    try {
      const token = localStorage.getItem('token')
      const response = await fetch(
        this._api_url + `/api/study_sessions/${study_session_id}/complete_video_lesson/`,
        {
          method: 'POST',
          headers: {
            ...this._headers,
            'authorization': `Token ${token}`
          },
          body: JSON.stringify({
            id
          })
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async getExternalDocumentLesson( {id} ) {
    try {
      const token = localStorage.getItem('token')
      const response = await fetch(
        this._api_url + `/api/external_document_lessons/${id}/`,
        {
          method: 'GET',
          headers: {
            ...this._headers,
            'authorization': `Token ${token}`
          },
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async completeExternalDocumentLesson ( {study_session_id, id} ) {
    try {
      const token = localStorage.getItem('token')
      const response = await fetch(
        this._api_url + `/api/study_sessions/${study_session_id}/complete_external_document_lesson/`,
        {
          method: 'POST',
          headers: {
            ...this._headers,
            'authorization': `Token ${token}`
          },
          body: JSON.stringify({
            id
          })
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async getInternalDocumentLesson( {id} ) {
    try {
      const token = localStorage.getItem('token')
      const response = await fetch(
        this._api_url + `/api/internal_document_lessons/${id}/`,
        {
          method: 'GET',
          headers: {
            ...this._headers,
            'authorization': `Token ${token}`
          },
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async completeInternalDocumentLesson ( {study_session_id, id} ) {
    try {
      const token = localStorage.getItem('token')
      const response = await fetch(
        this._api_url + `/api/study_sessions/${study_session_id}/complete_internal_document_lesson/`,
        {
          method: 'POST',
          headers: {
            ...this._headers,
            'authorization': `Token ${token}`
          },
          body: JSON.stringify({
            id
          })
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async getPracticalTask( {id} ) {
    try {
      const token = localStorage.getItem('token')
      const response = await fetch(
        this._api_url + `/api/practical_tasks/${id}/`,
        {
          method: 'GET',
          headers: {
            ...this._headers,
            'authorization': `Token ${token}`
          },
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async completePracticalTask ( {study_session_id, id} ) {
    try {
      const token = localStorage.getItem('token')
      const response = await fetch(
        this._api_url + `/api/study_sessions/${study_session_id}/complete_practical_task/`,
        {
          method: 'POST',
          headers: {
            ...this._headers,
            'authorization': `Token ${token}`
          },
          body: JSON.stringify({
            id
          })
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async getExams({ study_session_id }) {
    try {
      const token = localStorage.getItem('token')
      let url = this._api_url + `/api/exams/?study_session=${study_session_id}`
      const response = await fetch(
        url,
        {
          method: 'GET',
          headers: {
            ...this._headers,
            ...(token && {'authorization': `Token ${token}`})
          },
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async getExam({ id }) {
    try {
      const token = localStorage.getItem('token')
      let url = this._api_url + `/api/exams/${id}/`
      const response = await fetch(
        url,
        {
          method: 'GET',
          headers: {
            ...this._headers,
            ...(token && {'authorization': `Token ${token}`})
          },
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async startExam({ id }) {
    try {
      const token = localStorage.getItem('token')
      const response = await fetch(
        this._api_url + `/api/exams/${id}/start_exam/`,
        {
          method: 'POST',
          headers: {
            ...this._headers,
            'authorization': `Token ${token}`
          }
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async finishExam({ id }) {
    try {
      const token = localStorage.getItem('token')
      const response = await fetch(
        this._api_url + `/api/exams/${id}/finish_exam/`,
        {
          method: 'POST',
          headers: {
            ...this._headers,
            'authorization': `Token ${token}`
          }
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async submitAnswer({ exam, question, answer, user_answer }) {
    const token = localStorage.getItem('token')
    const method = user_answer ? 'PATCH' : 'POST';
    const url = user_answer ? `/api/user_answers/${user_answer.id}/` : `/api/user_answers/`
    const response = await fetch(
        this._api_url + url,
      {
        method: method,
        headers: {
          ...this._headers,
          'authorization': `Token ${token}`
        },
        body: JSON.stringify({
          exam,
          question,
          answer
        })
      }
    )
    return this.checkResponse(response)
  } catch (error) {
    console.error(error)
  }

  async getExamResult({ id }) {
    try {
      const token = localStorage.getItem('token')
      let url = this._api_url + `/api/exams/${id}/result/`
      const response = await fetch(
        url,
        {
          method: 'GET',
          headers: {
            ...this._headers,
            ...(token && {'authorization': `Token ${token}`})
          },
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async createBusinessRequest ({
    email, phone, name, company_name, language
  }) {
    try {
      const response = await fetch(
        this._api_url + '/api/business_request/',
        {
          method: 'POST',
          headers: {
            ...this._headers,
          },
          body: JSON.stringify({
            email, phone, name, company_name, language
          })
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }

  async getNumberOfStudents () {
    try {
      const response = await fetch(
        this._api_url + '/api/number_of_students/',
        {
          method: 'GET',
          headers: {
            ...this._headers,
          }
        }
      )
      return this.checkResponse(response)
    } catch (error) {
      console.error(error)
    }
  }
}

export default new Api(process.env.REACT_APP_API_URL || 'http://localhost:8000', { 'content-type': 'application/json' })

import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import styles from './styles.module.css'
import cn from 'classnames'

const CustomSelect = ({
    onChange,
    placeholder,
    label,
    disabled,
    type = 'text',
    inputClassName,
    labelClassName,
    className,
    name,
    required,
    onFocus,
    onBlur,
    value = '',
    options,
    error,
    errorText,
    defaultValue
  }) => {

  const [ inputValue, setInputValue ] = useState(value)
  const handleValueChange = (e) => {
    setInputValue(e.target.value)
    onChange(e)
  }
  useEffect(_ => {
    if (value !== inputValue) {
      setInputValue(value)
    }
  }, [value])


  return <div className={cn(styles.input, className)}>
    <label className={styles.inputLabel}>
      {label && <div className={cn(styles.inputLabelText, labelClassName)}>
        {label}
      </div>}
      <select
        value={inputValue}
        onChange={(e) => handleValueChange(e)}
        name={name}
        required={required}
        disabled={disabled}
        className={cn(styles.selectField, className)}
        defaultValue={defaultValue}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    </label>
    {error && 
      <div className={styles.selectError}>
        {errorText}
      </div>
    }
  </div>
}

export default CustomSelect

import { Container, Input, Title, Main, Form, Button, CustomSelect, ModalComponent } from '../../components'
import api from '../../api'
import styles from './styles.module.css'
import { useFormWithValidation } from '../../utils'
import { Redirect } from 'react-router-dom'
import { useContext, useState } from 'react'
import { AuthContext } from '../../contexts'
import MetaTags from 'react-meta-tags'
import { useTranslation } from 'react-i18next'

const SignUp = ({ setLoggedIn, history }) => {
  const { values, handleChange, errors, isValid, resetForm } = useFormWithValidation()
  const [ formErrors, setFormErrors ] = useState({})
  const [ modalIsOpen, setModalIsOpen ] = useState(false)
  const { t, i18n } = useTranslation()
  const authContext = useContext(AuthContext)

  const signUp = async ({ email, citizenship, first_name, last_name, iin, i_agree }) => {
    try {
      citizenship = citizenship === undefined ? 'KZ' : citizenship
      const language = i18n.language;
      let emailLowerCase = email.toLowerCase()
      await api.signup({email: emailLowerCase, citizenship, first_name, last_name, iin, i_agree, language });
      setModalIsOpen(true)
    } catch (errors) {
      setFormErrors(errors);
      setLoggedIn(false);
    }
  };

  const handleCloseModal = () => {
    setModalIsOpen(false)
    history.push('/signin')
  }

  return <Main>
    {authContext && <Redirect to='/main' />}
    <Container>
      <MetaTags>
        <title>{t('SignUp.SignUp')}</title>
        <meta name="description" content="Учебная платформа STIA - Регистрация" />
        <meta property="og:title" content={t('SignUp.SignUp')} />
      </MetaTags>
      <Title title={t('SignUp.SignUp')} />
      <Form className={styles.form} onSubmit={e => {
        e.preventDefault()
        signUp(values)
      }}>
        <Input
          label='Email'
          name='email'
          required
          onChange={handleChange}
          error={formErrors?.email}
          errorText={formErrors?.email}
        />
        <CustomSelect
          label={t('SignUp.Citizenship')}
          name='citizenship'
          required
          onChange={handleChange}
          options={
            [
              {
                'value': 'KZ', 'label': t('SignUp.KZ')
              },
              {
                'value': 'OTHER', 'label': t('SignUp.Other')
              }
            ]
          }
          error={formErrors?.citizenship}
          errorText={formErrors?.citizenship}
          defaultValue={{
            'value': 'KZ', 'label': t('SignUp.KZ')
          }}
        />
        <Input
          label={t('SignUp.Name')}
          name='first_name'
          required
          onChange={handleChange}
          error={formErrors?.first_name}
          errorText={formErrors?.first_name}
        />
        <Input
          label={t('SignUp.LastName')}
          name='last_name'
          required
          onChange={handleChange}
          error={formErrors?.last_name}
          errorText={formErrors?.last_name}
        />
        <Input
          label={t('SignUp.Iin')}
          name='iin'
          required
          onChange={handleChange}
          maxLength={12}
          error={formErrors?.iin}
          errorText={formErrors?.iin}
        />
        <Input
          type='checkbox'
          label={t('SignUp.IAgree')}
          name='i_agree'
          inputClassName={styles.checkbox}
          required
          onChange={handleChange}
          error={formErrors?.i_agree}
          errorText={formErrors?.i_agree}
        />
        <Button
          modifier='style_dark-blue'
          type='submit'
          className={styles.button}
          disabled={!isValid}
        >
          {t('SignUp.SignUp')}
        </Button>
      </Form>
      <ModalComponent
        modalIsOpen={modalIsOpen}
        handleOkModal={handleCloseModal}
        handleCloseModal={handleCloseModal}
        modalTitle={t('SignUp.Success')}
      />
    </Container>
  </Main>
}

export default SignUp

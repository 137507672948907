import './App.css';
import { Switch, Route, useHistory, Redirect } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Header, Footer, ProtectedRoute } from './components'
import api from './api'
import styles from './styles.module.css'
import cn from 'classnames'
import hamburgerImg from './images/hamburger-menu.png'

import {
  Main,
  Courses,
  SignIn,
  SignUp,
  ChangePassword,
  ResetPassword,
  ConfirmResetPassword,
  SetNewPassword,
  ConfirmSetNewPassword,
  ProfilePage,
  ConfirmEmail,
  SingleCourse,
  StudySession,
  SingleVideoLesson,
  SingleExternalDocumentLesson,
  SingleInternalDocumentLesson,
  SinglePracticalTask,
  Exam,
  ExamResult,
  NotFound,
  Forbidden,
  ServerError
} from './pages'

import { AuthContext, UserContext, LangContext } from './contexts'

function App() {
  const [ loggedIn, setLoggedIn ] = useState(null)
  const [ user, setUser ] = useState(null)
  const [ lang, setLang ] = useState(null);
  const [ menuToggled, setMenuToggled ] = useState(false)
  const { t, i18n } = useTranslation();

  const history = useHistory()

  const getUser = async () => {
    const token = localStorage.getItem('token')
    if (token) {
      try {
        const response = await api.getUser()
        if (response) {
          setUser(response)
          setLoggedIn(true)
          i18n.changeLanguage(response.language)
        }
      }
      catch (error) {
        setLoggedIn(false)
        history.push('/signin')
      }
    }
    else {
      setLoggedIn(false)
    }
    setLang(i18n.language);
  }

  const onSignOut = async () => {
    try {
      const response = await api.signOut()
      localStorage.removeItem('token')
      setLoggedIn(false)
    }
    catch (error) {
      console.error(error)
    }
  }

  useEffect(_ => {
    getUser()
  }, [])

  if (loggedIn === null) {
    return <div className={styles.loading}>Loading!!</div>
  }
  
  return <AuthContext.Provider value={loggedIn}>
    <UserContext.Provider value={user}>
    <LangContext.Provider value={lang}>
      <div className={cn("App", {
        [styles.appMenuToggled]: menuToggled
      })}>
        <div
          className={styles.menuButton}
          onClick={_ => setMenuToggled(!menuToggled)}
        >
          <img src={hamburgerImg} />
        </div>
        <Header loggedIn={loggedIn} onSignOut={onSignOut} user={user}/>
        <Switch>

          <ProtectedRoute
            // exact
            path='/profile'
            component={ProfilePage}
            loggedIn={loggedIn}
            history={history}
          />

          <ProtectedRoute
            exact
            path='/change-password'
            component={ChangePassword}
            loggedIn={loggedIn}
            history={history}
          />

          <Route
            exact
            path='/courses/:id'
          >
            <SingleCourse
              loggedIn={loggedIn}
              history={history}
            />
          </Route>

          <Route exact path='/main'>
            <Main
              history={history}
            />
          </Route>

          <Route exact path='/courses'>
            <Courses
              history={history}
            />
          </Route>

          <ProtectedRoute
            exact
            path='/study_sessions/:study_session_id/video_lessons/:id'
            component={SingleVideoLesson}
            loggedIn={loggedIn}
            history={history}
          />

          <ProtectedRoute
            exact
            path='/study_sessions/:study_session_id/external_document_lessons/:id'
            component={SingleExternalDocumentLesson}
            loggedIn={loggedIn}
            history={history}
          />

          <ProtectedRoute
            exact
            path='/study_sessions/:study_session_id/internal_document_lessons/:id'
            component={SingleInternalDocumentLesson}
            loggedIn={loggedIn}
            history={history}
          />

          <ProtectedRoute
            exact
            path='/study_sessions/:study_session_id/practical_tasks/:id'
            component={SinglePracticalTask}
            loggedIn={loggedIn}
            history={history}
          />

          <ProtectedRoute
            exact
            path='/study_sessions/:id'
            component={StudySession}
            loggedIn={loggedIn}
            history={history}
          />

          <ProtectedRoute
            exact
            path='/exams/:id'
            component={Exam}
            loggedIn={loggedIn}
            history={history}
          />

          <ProtectedRoute
            exact
            path='/exams/:id/result'
            component={ExamResult}
            loggedIn={loggedIn}
            history={history}
          />

          <Route exact path='/signin'>
            <SignIn
              setLoggedIn={setLoggedIn}
              setUser={setUser}
              history={history}
            />
          </Route>

          <Route exact path='/signup'>
            <SignUp
              setLoggedIn={setLoggedIn}
              history={history}
            />
          </Route>

          <Route exact path='/confirm_email'>
            <ConfirmEmail />
          </Route>

          <Route exact path='/reset_password'>
            <ResetPassword
              history={history}
            />
          </Route>

          <Route exact path='/confirm_reset_password'>
            <ConfirmResetPassword />
          </Route>

          <Route exact path='/set_new_password'>
            <SetNewPassword
              history={history}
            />
          </Route>

          <Route exact path='/confirm_set_new_password'>
            <ConfirmSetNewPassword />
          </Route>

          <Route exact path='/404'>
            <NotFound />
          </Route>

          <Route exact path='/403'>
            <Forbidden />
          </Route>

          <Route exact path='/500'>
            <ServerError />
          </Route>

          <Route path='/'>
            {loggedIn ? <Redirect to='/main' /> : <Redirect to='/signin'/>}
          </Route>

        </Switch>
        <Footer />
      </div>
    </LangContext.Provider>
    </UserContext.Provider>
  </AuthContext.Provider>
}

export default App;

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './styles.module.css'
import cn from 'classnames'

const Input = ({
    onChange,
    placeholder,
    label,
    disabled,
    type = 'text',
    inputClassName,
    labelClassName,
    className,
    name,
    required,
    onFocus,
    onBlur,
    value = '',
    maxLength,
    error,
    errorText
  }) => {

  const [ inputValue, setInputValue ] = useState(value)
  const handleValueChange = (e) => {
    let value
    if (e.target.type == 'checkbox') {
        value = e.target.checked ? 'true' : 'false'
    }
    else {
        value = e.target.value
    }
    setInputValue(value)
    onChange(e)
  }
  useEffect(_ => {
    if (value !== inputValue) {
      setInputValue(value)
    }
  }, [value])


  return <div className={cn(styles.input, className)}>
    <label className={styles.inputLabel}>
      {label && <div className={cn(styles.inputLabelText, labelClassName)}>
        {label}
      </div>}
      <input
        type={type}
        required={required}
        name={name}
        className={type == 'checkbox' ? inputClassName : cn(styles.inputField, inputClassName)}
        onChange={e => {
          handleValueChange(e)
        }}
        onFocus={onFocus}
        value={inputValue}
        onBlur={onBlur}
        maxLength={maxLength}
        placeholder={placeholder}
      />
    </label>
    {error && 
      <div className={styles.inputError}>
          {errorText}
      </div>
    }
  </div>
}

export default Input

import { Container, Main, Title, CustomToast, Loader, StudySessionNavigation } from '../../components'
import { useState, useEffect } from 'react'
import { Badge, Breadcrumb } from 'react-bootstrap'
import styles from './styles.module.css'
import { useParams, useLocation } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import api from '../../api'
import { PdfViewer } from '../../components'
import { useTranslation } from 'react-i18next'
import queryString from "query-string"


const SingleInternalDocumentLesson = ({ history }) => {

  const [ internalDocumentLesson, setInternalDocumentLesson ] = useState({})
  const [ studySession, setStudySession ] = useState(null)
  const [ lessonNeighbours, setLessonNeighbours ] = useState({})
  const [ apiError, setApiError ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);

  const { t } = useTranslation()
  const { id, study_session_id } = useParams()
  const location = useLocation();
  const searchParams = queryString.parse(location.search);

  const getInternalDocumentLesson = async (id) => {
    try {
      const response = await api.getInternalDocumentLesson({ id })
      setInternalDocumentLesson(response)
    }
    catch (error) {
      console.error(error)
    }
  }

  const setInternalDocumentLessonCompleted = async () => {
    try {
      const response = await api.completeInternalDocumentLesson({ study_session_id, id })
      setStudySession(response)
    }
    catch (error) {
      console.error(error)
      setApiError(true)
    }
  }

  const getStudySession = async (id) => {
    try {
      const response = await api.getStudySession({ id })
      setStudySession(response)
    }
    catch (error) {
      console.error(error)
    }
  }

  const getLessonNeighbours = async (study_session_id, id, module_id) => {
    try {
      const response = await api.getLessonNeighbours({study_session_id: study_session_id, lesson_id: id, lesson_type: 'internal_document_lessons', module_id: module_id})
      setLessonNeighbours(response)
    }
    catch (error) {
      console.error(error)
    }
  }

  const fetchData = async () => {
    setIsLoading(true);
    await getInternalDocumentLesson(id);
    await setInternalDocumentLessonCompleted();
    await getStudySession(study_session_id);
    await getLessonNeighbours(study_session_id, id, searchParams.module_id);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [])
  
  return (isLoading ? <Loader /> :
    <Main>
      <CustomToast
        headerText={t('Errors.Error')}
        bodyText={t('Errors.CommonError')}
        onClose={() => setApiError(false)}
        show={apiError}
        delay={3000}
      />
      <Container>
        <MetaTags>
          <title>{internalDocumentLesson.name}</title>
          <meta name="description" content={`Учебная платформа STIA - ${internalDocumentLesson.name}`} />
          <meta property="og:title" content={internalDocumentLesson.name} />
        </MetaTags>
        <div className={styles.content}>
          <Title
            title={internalDocumentLesson.name}
            badge={studySession?.completed_internal_document_lessons_ids.includes(Number(id)) && <Badge bg='success'>{t('StudySessionCard.Completed')}</Badge>}
          />
          <Breadcrumb>
            <Breadcrumb.Item href={'/profile'}>{t('Header.Profile')}</Breadcrumb.Item>
            <Breadcrumb.Item href={`/study_sessions/${study_session_id}`}>{t('CourseCard.Course')}</Breadcrumb.Item>
            <Breadcrumb.Item active>{internalDocumentLesson.name}</Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.content_text}>
            <div dangerouslySetInnerHTML={{ __html: internalDocumentLesson.content }} />
          </div>
          <PdfViewer fileUrl={internalDocumentLesson.file} />
          <StudySessionNavigation prevLesson={lessonNeighbours.prev} studySession={studySession} nextLesson={lessonNeighbours.next} history={history} currentLessonType={'internal_document_lessons'}/>
        </div>
      </Container>
    </Main>
  )
}

export default SingleInternalDocumentLesson


import styles from './styles.module.css'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { LinkComponent } from '../index.js'
import { AuthContext, UserContext } from '../../contexts'
import './dropdown.css'
import userContext from '../../contexts/user-context';

const AccountMenu = ({ onSignOut }) => {
  const authContext = useContext(AuthContext);
  const user = useContext(UserContext);
  const { t, i18n } = useTranslation();

  if (!authContext) {
    return <div className={styles.menu}>
      <LinkComponent
        className={styles.menuButton}
        href='/signin'
        title={t('Header.SignIn')}
      />
      <LinkComponent
        href='/signup'
        title={t('Header.SignUp')}
        className={styles.menuButton}
      />
      <DropdownButton id="dropdown-basic-button" title={t('Header.Language')}>
        <Dropdown.Item onClick={() => {i18n.changeLanguage('ru')}}>Русский</Dropdown.Item>
        <Dropdown.Item onClick={() => {i18n.changeLanguage('kk')}}>Қазақ</Dropdown.Item>
        <Dropdown.Item onClick={() => {i18n.changeLanguage('en')}}>English</Dropdown.Item>
      </DropdownButton>
    </div>
  }
  return <div className={styles.menu}>
    {authContext === true && user?.is_staff === true &&
    <a
      className={styles.menuLink}
      href={`${process.env.REACT_APP_API_URL}/admin` || "http://localhost:8000/admin"}
    >
      Администрирование
    </a>
    }
    <LinkComponent
      className={styles.menuLink}
      href='/change-password'
      title={t('Header.ChangePassword')}
    />
    <a
      className={styles.menuLink}
      onClick={onSignOut}
    >
      {t('Header.LogOut')}
    </a>
  </div>
}


export default AccountMenu
import styles from './style.module.css'
import { LinkComponent, Icons, Button } from '../index'
import { useContext } from 'react'
import { Badge } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { UserContext } from '../../contexts'

const CourseCard = ({
  id,
  name = 'Без названия',
  description,
  image,
  cost,
  sum_completion_time,
  with_certificate,
  has_new_or_review_course_request,
  is_new,
  is_free,
  handleButtonClick,
  userOnContract
}) => {

  const { t } = useTranslation();
  const user = useContext(UserContext);

  let completionTimetext = ''
  sum_completion_time = Number(sum_completion_time)
  if (0 <= sum_completion_time && sum_completion_time < 60) {
    completionTimetext = `${sum_completion_time} ${t('Time.Sec')}.`
  }
  else if (60 <= sum_completion_time && sum_completion_time < 3600) {
    completionTimetext = `${(sum_completion_time / 60).toFixed(0)} ${t('Time.Minute')}.`
  }
  else {
    completionTimetext = `${(sum_completion_time / 3600).toFixed(1)} ${t('Time.Hour')}.`
  }

  return <div className={styles.card}>
      <LinkComponent
        className={styles.card__title}
        href={`/courses/${id}`}
        title={<div className={styles.card__image} style={{ backgroundImage: `url(${ image })` }} />}
      />
      <div className={styles.card__body}>
        <LinkComponent
          className={styles.card__title}
          href={`/courses/${id}`}
          title={name}
        />
        <div className={styles.card__text}>
          {description}
        </div>
        <div className={styles.card__time}>
          <Icons.ClockIcon /> {completionTimetext}
        </div>
        {is_new && <Badge className={styles.card__new} bg='info'>{t('Status.New')}</Badge>}
        {cost && !is_free === true
          ?
          <div className={styles.card__text}>
            <span className={styles.card__highlight}>{t('CourseCard.Price')}: </span>{cost + ' ₸'}
          </div>
          :
          is_free &&
            <div className={styles.card__text}>
              <span className={styles.card__highlight}>{t('CourseCard.Price')}: </span>{t('CourseCard.Free')}
            </div>
        }

        <div className={styles.card__text}>
          <span className={styles.card__highlight}>{t('CourseCard.Certificate')}: </span>{with_certificate ? t('CourseCard.IsGiven') : t('CourseCard.IsNotGiven')}
        </div>
      </div>
      
      <div className={styles.card__footer}>
        {!user?.is_on_contract &&
          <Button
            className={styles.card__add}
            clickHandler={handleButtonClick}
            disabled={has_new_or_review_course_request}
          >
            {has_new_or_review_course_request ? t('CourseCard.OnReview') : t('CourseCard.CreateRequest')}
          </Button>
        }
        <div className={styles.card__author}>
          <LinkComponent
            href={`/courses/${id}`}
            title={t('CourseCard.MoreAboutCourse')}
            className={styles.card__link}
          />
        </div>
      </div>
  </div>
}

export default CourseCard
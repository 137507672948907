import styles from './style.module.css'
import cn from 'classnames'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { LinkComponent } from '../index'
import navigation from '../../configs/navigation'
import { useTranslation } from 'react-i18next'
import stiaLogo from '../../images/STIA_LOGO.png'

const Nav = ({ loggedIn }) => {

  const { i18n } = useTranslation();
  const history = useHistory();

  return <nav className={styles.nav}>
    <div className={styles.nav__container}>
      <div className={styles.logo__container} onClick={() => history.push('/main')}>
        <img src={stiaLogo} />
      </div>
      <ul className={styles.nav__items}>
        {navigation.map(item => {
          if (!loggedIn && item.auth) { return null }
          return <li className={cn(styles.nav__item, {
            [styles.nav__item_active]: false
          })} key={item.href}>
            <LinkComponent
              title={item[`title_${i18n.language}`]}
              activeClassName={styles.nav__link_active}
              href={item.href}
              exact
              className={styles.nav__link}
            />
          </li>
        })}
      </ul>
    </div>
  </nav>
}

export default Nav
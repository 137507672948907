import { Container, Input, Title, Main, Form, Button } from '../../components'
import api from '../../api'
import styles from './styles.module.css'
import { useFormWithValidation } from '../../utils'
import MetaTags from 'react-meta-tags'
import { useTranslation } from 'react-i18next'

const ResetPassword = ({ history }) => {
  const { values, handleChange, errors, isValid, resetForm } = useFormWithValidation()
  const { t } = useTranslation()

  const resetPassword = async ({ email }) => {
    const response = await api.resetPassword({ email })
    if (response) {
        history.push('/confirm_reset_password')
    }
  }

  return <Main>
    <Container>
      <MetaTags>
        <title>{t('ResetPassword.ResetPassword')}</title>
        <meta name="description" content="Учебная платформа STIA - сбросить пароль" />
        <meta property="og:title" content={t('ResetPassword.ResetPassword')} />
      </MetaTags>
      <Title title={t('ResetPassword.ResetPassword')} />
      <Form
        className={styles.form}
        onSubmit={e => {
          e.preventDefault()
          resetPassword(values)
        }}
      >
        <Input
          required
          label='Email'
          name='email'
          onChange={handleChange}
        />
        <Button
          modifier='style_dark-blue'
          disabled={!isValid}
          type='submit'
          className={styles.button}
        >
          {t('ResetPassword.ResetPassword')}
        </Button>
      </Form>
    </Container>
  </Main>
}

export default ResetPassword

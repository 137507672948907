import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import NotFound from './pages/404';

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false,
    },
    lng: 'ru',
    resources: {
      ru : {
        translation: {
          Header: {
            Language: 'RU',
            SignIn: 'Войти',
            SignUp: 'Создать аккаунт',
            ChangePassword: 'Изменить пароль',
            LogOut: 'Выход',
            Profile: 'Профиль',
            Main: 'Главная'
          },
          Time: {
            Sec: 'сек',
            Minute: 'мин',
            Hour: 'ч',
          },
          Status: {
            New: 'Новый',
            Progress: 'Выполняется',
            Fail: 'Не сдан',
            Pass: 'Успешно сдан',
          },
          CourseCard: {
            Course: 'Курс',
            Price: 'Стоимость',
            Certificate: 'Сертификат',
            IsGiven: 'Выдается',
            IsNotGiven: 'Не выдается',
            OnReview: 'Ваша заявка рассматривается',
            CreateRequest: 'Оставить заявку на курс',
            MoreAboutCourse: 'Подробнее о курсе',
            CourseList: 'Список курсов',
            CourseType: 'Тип курса',
            SearchByName: 'Поиск по имени',
            SuccessCourseRequest: 'Заявка успешно отправлена! Ее статус вы можете отслеживать в Профиле',
            Modules: 'Модули',
            Free: 'Бесплатно'
          },
          ExamCard: {
            Continue: 'Продолжить',
            CheckErrors: 'Посмотреть ошибки',
            Start: 'Начать',
            AvailableAttempts: 'Доступных попыток',
            Type: 'Тип',
            TestType: 'Пробный',
            RealType: 'Контрольный',
            PercentOfCorrect: 'Процент правильных ответов',
            CourseExam: 'Экзамен по курсу {{courseName}}',
            CorrectAnswer: 'Правильный ответ',
            FinishExam: 'Завершить экзамен',
            ConfirmFinishExam: 'Вы действительно хотите завершить экзамен? Вы дали ответ на {{amount}} вопросов из {{totalAmount}}',
            ToPrevQuestion: 'Предыдущий вопрос',
            ToNextQuestion: 'Следующий вопрос',
            PassPercent: 'Процент для прохождения экзамена'
          },
          Lesson: {
            Lessons: 'Уроки',
            Completed: 'Завершен',
            Start: 'Пройти',
            VideoLessons: 'Видеоуроки',
            ExternalLessons: 'Законодательные документы, НПА, документы по стандартизации',
            InternalLessons: 'Дополнительные материалы',
            PracticalTasks: 'Практические задания',
            PrevLesson: 'Предыдущий урок',
            ToStudySession: 'К курсу',
            NextLesson: 'Следующий урок'
          },
          Pagination: {
            Page: 'Стр.',
            Of: 'из'
          },
          StudySessionCard: {
            ModulesCompleted: 'Завершено модулей',
            Progress: 'Общий прогресс',
            Completed: 'Завершен',
            AvailableExams: 'Доступные экзамены',
            Congrats: 'Поздравляем, вам доступен финальный экзамен! Крайне рекомендуем сначала попрактиковаться в пробном формате',
            TestExams: 'Пробный экзамен',
            RealExams: 'Контрольный экзамен',
            Start: 'Вы действительно хотите начать экзамен?',
            Progress: 'Прогресс в курсе',
            ProgressPercent: 'Прогресс в курсе {{percent}}%',
          },
          ChangePassword: {
            ForgotPassword: 'Забыли пароль?',
            Password: 'Пароль',
            ChangePassword: 'Изменить пароль',
            OldPassword: 'Старый пароль',
            NewPassword: 'Новый пароль',
            NewPasswordRepeat: 'Подтверждение нового пароля',
            SuccessPasswordChange: 'Пароль успешно изменен'
          },
          ConfirmEmail: {
            ConfirmEmail: 'Подтверждение почты',
            SuccessConfirmEmail: 'Почта успешно подтверждена! В течение 5 минут вам будут высланы данные для входа',
            WrongLink: 'Неверная ссылка!',
            ToMain: 'На главную'
          },
          ResetPassword: {
            ResetPassword: 'Сбросить пароль',
          },
          ConfirmResetPassword: {
            ConfirmResetPassword: 'Подтверждение сброса пароля',
            SuccessConfirmResetPassword: 'На указанную почту в течение 5 минут придет письмо со ссылкой для сброса пароля'
          },
          SetNewPassword: {
            SetNewPassword: 'Задать новый пароль',
            ConfirmNewPassword: 'Подтвердить новый пароль',
            OldLink: 'Ссылка устарела. Запросите сброс пароля заново'
          },
          ConfirmSetNewPassword: {
            ConfirmSetNewPassword: 'Подтверждение установки нового пароля',
            SuccessConfirmSetNewPassword: 'Ваш пароль успешно изменен. Вы можете войти в аккаунт с новым паролем'
          },
          Errors: {
            SignInError: 'Неправильный логин и/или пароль',
            Error: 'Ошибка',
            CommonError: 'Что-то пошло не так. Сообщите, пожалуйста, нам об ошибке',
            NotFound: 'Страница не найдена',
            Forbidden: 'Доступ запрещен',
            ForbiddenMsg: 'К сожалению, у вас нет доступа к этой странице',
            ServerError: 'Внутренняя ошибка',
            ServerErrorMsg: 'Что-то пошло не так. Мы уже работаем над исправлением ошибки'
          },
          SignUp: {
            SignUp: 'Регистрация',
            Citizenship: 'Гражданство',
            KZ: 'Казахстан',
            Other: 'Другое',
            Name: 'Имя',
            LastName: 'Фамилия',
            Iin: 'ИИН',
            IAgree: 'Даю согласие на обработку и хранение персональных данных',
            Success: 'В течение 5 минут на указанную почту будет отправлена ссылка для подтверждения email'
          },
          User: {
            RequestDate: 'Дата заявки',
            Course: 'Курс',
            Status: 'Статус',
            Approved: 'Одобрена',
            Rejected: 'Отклонена',
            Review: 'На рассмотрении',
            New: 'Новая',
            UserProfile: 'Профиль пользователя',
            PersonalInfo: 'Личная информация',
            Phone: 'Телефон',
            Company: 'Компания',
            Position: 'Должность',
            Address: 'Адрес',
            Language: 'Язык',
            Apply: 'Применить',
            Document: 'Документ',
            DocumentNumber: 'Номер удостоверения личности/паспорта',
            BirthDate: 'Дата рождения',
            DocumentIssuingAuthority: 'Орган выдачи удостоверения личности/паспорта',
            DocumentExpDate: 'Дата окончания действия удостоверения личности/паспорта',
            CourseRequests: 'Заявки на курсы',
            MyCourses: 'Мои курсы',
            ChangesApplied: 'Изменения применены'
          },
          Main: {
            Title: 'Учитесь без ограничений',
            Maintext: '«Обучение - это не то, что получаешь случайно. А то, к чему стремишься со страстью и делаешь с усердием.» Эбигейл Адамс',
            EducatedStudents: 'Успешно прошли обучение {{amount}} человек',
            JoinForFree: 'Присоединиться бесплатно',
            SolutionsForBusiness: 'Решения для бизнеса',
            Companies: 'Наши успешные партнерства',
            PopularCourses: 'Популярные курсы',
            NewCourses: 'Новые курсы',
            FreeCourses: 'Бесплатные курсы',
            BusinessTitle: 'Решения для бизнеса',
            EmailPlaceholder: 'Ваш email',
            PhonePlaceholder: 'Ваш телефон в формате +7XXXXXXXXXX',
            NamePlaceholder: 'Ваше имя',
            CompanyPlaceholder: 'Название компании',
            Submit: 'Отправить',
            EmailInvalid: 'Введите корректный email',
            PhoneInvalid: 'Введите корректный телефон',
            SuccessRequest: 'Ваш запрос успешно отправлен! Менеджер свяжется с вами в ближайшее время',
            BusinessText: 'Вы можете подать заявку от компании, в этом случае будет предусмотрена скидка, в зависимости от количества обучаемых:',
            Business5Percent: 'от 15 до 20 человек – скидка 5 %,',
            Business10Percent: 'от 20 до 50 человек – скидка 10 %,',
            Business15Percent: 'от 50 человек – скидка 15 %'
          },
          Yes: 'Да',
          No: 'Нет'
        }
      },
      en: {
        translation: {
          Header: {
            Language: 'EN',
            SignIn: 'Sign In',
            SignUp: 'Sign Up',
            ChangePassword: 'Change password',
            LogOut: 'Log out',
            Profile: 'Profile',
            Main: 'Main page'
          },
          Time: {
            Sec: 'sec',
            Minute: 'min',
            Hour: 'h',
          },
          Status: {
            New: 'New',
            Progress: 'In progress',
            Fail: 'Failed',
            Pass: 'Succesfully passed',
          },
          CourseCard: {
            Course: 'Course',
            Price: 'Price',
            Certificate: 'Certificate',
            IsGiven: 'Is given',
            IsNotGiven: 'Is not given',
            OnReview: 'Your request is being reviewed',
            CreateRequest: 'Apply for a course',
            MoreAboutCourse: 'More about course',
            CourseList: 'Courses list',
            CourseType: 'Course type',
            SearchByName: 'Search by name',
            SuccessCourseRequest: 'Your request has been successfully sent! You can track its status in the Profile',
            Modules: 'Modules',
            Free: 'Free'
          },
          ExamCard: {
            Continue: 'Continue',
            CheckErrors: 'Check errors',
            Start: 'Start',
            AvailableAttempts: 'Available attempts',
            Type: 'Type',
            TestType: 'Test',
            RealType: 'Control',
            PercentOfCorrect: 'Percentage of correct answers',
            CourseExam: 'Course exam {{courseName}}',
            CorrectAnswer: 'Correct answer',
            FinishExam: 'Finish exam',
            ConfirmFinishExam: 'Do you really want to complete the exam? You answered {{amount}} questions out of {{totalAmount}}',
            ToPrevQuestion: 'Previous question',
            ToNextQuestion: 'Next question',
            PassPercent: 'Percentage to pass the exam'
          },
          Lesson: {
            Lessons: 'Lessons',
            Completed: 'Completed',
            Start: 'Start',
            VideoLessons: 'Videolessons',
            ExternalLessons: 'Legislative documents, NAPs, standardization documents',
            InternalLessons: 'Additional documents',
            PracticalTasks: 'Practical tasks',
            PrevLesson: 'Previous lesson',
            ToStudySession: 'To course',
            NextLesson: 'Next lesson'
          },
          Pagination: {
            Page: 'Page',
            Of: 'of'
          },
          StudySessionCard: {
            ModulesCompleted: 'Modules completed',
            Progress: 'Total progress',
            Completed: 'Completed',
            AvailableExams: 'Available exams',
            Congrats: 'Congratulations, the final exam is available to you! It is highly recommended that you practice the test format first',
            TestExams: 'Test exam',
            RealExams: 'Real exam',
            Start: 'Do you really want to start the exam?',
            Progress: 'Progress on the course',
            ProgressPercent: 'Progress on the course {{percent}}%',
          },
          ChangePassword: {
            ForgotPassword: 'Forgot password?',
            Password: 'Password',
            ChangePassword: 'Change password',
            OldPassword: 'Old password',
            NewPassword: 'New password',
            NewPasswordRepeat: 'Repeat new password',
            SuccessPasswordChange: 'Password successfully changed'
          },
          ConfirmEmail: {
            ConfirmEmail: 'Confirm email',
            SuccessConfirmEmail: "Email is successfully confirmed! Within 5 minutes you will be sent your login details",
            WrongLink: 'Wrong link!',
            ToMain: 'To main'
          },
          ResetPassword: {
            ResetPassword: 'Reset password',
          },
          ConfirmResetPassword: {
            ConfirmResetPassword: 'Confirm password reset',
            SuccessConfirmResetPassword: 'An email with a link to reset the password will be sent to the specified email within 5 minutes'
          },
          SetNewPassword: {
            SetNewPassword: 'Set new password',
            ConfirmNewPassword: 'Confirm new password',
            OldLink: 'The link is out of date. Request a new password reset'
          },
          ConfirmSetNewPassword: {
            ConfirmSetNewPassword: 'Confirming the new password setting',
            SuccessConfirmSetNewPassword: 'Your password has been successfully changed. You can log in to your account with the new password'
          },
          Errors: {
            SignInError: 'Wrong login and/or password',
            Error: 'Error',
            CommonError: 'Something went wrong. Please notify us of the error',
            NotFound: 'Page not found',
            Forbidden: 'Access denied',
            ForbiddenMsg: 'Unfortunately, you do not have access to this page',
            ServerError: 'Internal error',
            ServerErrorMsg: "Something went wrong. We're already working on fixing it"
          },
          SignUp: {
            SignUp: 'SignUp',
            Citizenship: 'Citizenship',
            KZ: 'Kazakhstan',
            Other: 'Other',
            Name: 'Name',
            LastName: 'Last name',
            Iin: 'IIN',
            IAgree: 'I consent to the processing and storage of personal data',
            Success: 'Within 5 minutes, an email confirmation link will be sent to the specified email address'
          },
          User: {
            RequestDate: 'Request date',
            Course: 'Course',
            Status: 'Status',
            Approved: 'Approved',
            Rejected: 'Rejected',
            Review: 'Review',
            New: 'New',
            UserProfile: 'User profile',
            PersonalInfo: 'Personal info',
            Phone: 'Phone number',
            Company: 'Company',
            Position: 'Position',
            Address: 'Address',
            Language: 'Language',
            Apply: 'Apply',
            Document: 'Document',
            DocumentNumber: 'Identity card/passport number',
            BirthDate: 'Birth date',
            DocumentIssuingAuthority: 'Identity card/passport issuing authority',
            DocumentExpDate: 'Date of expiration of identity card/passport',
            CourseRequests: 'Course requests',
            MyCourses: 'My courses',
            ChangesApplied: 'Changes applied'
          },
          Main: {
            Title: 'Learn without limits',
            Maintext: "«Learning is not something you get by accident. It's something you pursue with passion and do with diligence.» Abigail Adams",
            EducatedStudents: 'Successfully trained {{amount}} people',
            JoinForFree: 'Join for free',
            SolutionsForBusiness: 'Solutions for business',
            Companies: 'Our successful partnerships',
            PopularCourses: 'Popular courses',
            NewCourses: 'New courses',
            FreeCourses: 'Free courses',
            BusinessTitle: 'Solutions for business',
            EmailPlaceholder: 'Your email',
            PhonePlaceholder: 'Your phone in +7XXXXXXXXXX format',
            NamePlaceholder: 'Your name',
            CompanyPlaceholder: 'Company name',
            Submit: 'Submit',
            EmailInvalid: 'Email is invalid',
            PhoneInvalid: 'Phone is invalid',
            SuccessRequest: 'Your request is send successfully! Manager will contact you shortly',
            BusinessText: 'You can apply from a company, in which case there will be a discount, depending on the number of trainees:',
            Business5Percent: 'from 15 to 20 people - 5% discount,',
            Business10Percent: 'from 20 to 50 people - 10% discount,',
            Business15Percent: 'from 50 people - 15% discount'
          },
          Yes: 'Yes',
          No: 'No'
        }
      },
      kk: {
        translation: {
          Header: {
            Language: 'KZ',
            SignIn: 'Кіру',
            SignUp: 'Аккаунты құру',
            ChangePassword: 'Құпия сөзді өзгерту',
            LogOut: 'Шығу',
            Profile: 'Профиль',
            Main: 'Басты бет'
          },
          Time: {
            Sec: 'сек',
            Minute: 'мин',
            Hour: 'сағ',
          },
          Status: {
            New: 'Жаңа',
            Progress: 'Орындалды',
            Fail: 'Өткізілмеді',
            Pass: 'Сәтті өткізілді',
          },
          CourseCard: {
            Course: 'Курс',
            Price: 'Бағасы',
            Certificate: 'Сертификат',
            IsGiven: 'Беріледі (Ұсынылады)',
            IsNotGiven: 'Берілмейді (Ұсынылмайды)',
            OnReview: 'Сіздің өтінішіңіз қарастырылуда',
            CreateRequest: 'Курсқа өтініш жіберу',
            MoreAboutCourse: 'Курс туралы толық ақпарат',
            CourseList: 'Курстар тізімі',
            CourseType: 'Курс түрі',
            SearchByName: 'Аты бойынша іздеу',
            SuccessCourseRequest: 'Өтінім сәтті жіберілді! Сіз оның күйін профильде бақылай аласыз',
            Modules: 'Модуль',
            Free: 'Тегін'
          },
          ExamCard: {
            Continue: 'Жалғастыру',
            CheckErrors: 'Қателерді көру',
            Start: 'Бастау',
            AvailableAttempts: 'Қолжетімді сынақтар',
            Type: 'Түр',
            TestType: 'Сынақ',
            RealType: 'Бақылау',
            PercentOfCorrect: 'Дұрыс жауаптар проценті',
            CourseExam: 'Курс бойынша емтихан {{courseName}}',
            CorrectAnswer: 'Дұрыс жауап',
            FinishExam: 'Емтиханды аяқтау',
            ConfirmFinishExam: 'Сіз емтиханды аяқтағыңыз келе ме? Сіз емтихан бойынша {{totalAmount}} сұрақтың {{amount}} сұрағына жауап бердіңіз',
            ToPrevQuestion: 'Алдыңғы сұрақ',
            ToNextQuestion: 'Келесі сұрақ',
            PassPercent: 'Емтиханнан өту пайызы'
          },
          Lesson: {
            Lessons: 'Сабақ',
            Completed: 'Аяқталды',
            Start: 'Өту',
            VideoLessons: 'Бейне сабақ',
            ExternalLessons: 'Заңнамалық құжаттар, ережелер, стандарттау құжаттары',
            InternalLessons: 'Қосымша құжаттар',
            PracticalTasks: 'Практикалық тапсырмалар',
            PrevLesson: 'Алдыңғы сабақ',
            ToStudySession: 'Курсқа оралу',
            NextLesson: 'Келесі сабақ'
          },
          Pagination: {
            Page: 'Бет',
            Of: 'Бастап'
          },
          StudySessionCard: {
            ModulesCompleted: 'Аяқталған модулдер',
            Progress: 'Жалпы прогресс',
            Completed: 'Аяқталған',
            AvailableExams: 'Қолжетімді емтихан',
            Congrats: 'Құттықтаймыз, сіз қорытынды емтиханға жеттіңіз! Алдымен сынақ форматында жаттығуды ұсынамыз',
            TestExams: 'Сынақ емтихан',
            RealExams: 'Бақылау емтихан',
            Start: 'Емтиханды бастағыңыз келетініне сенімдісіз бе?',
            Progress: 'Курс прогрессі',
            ProgressPercent: 'Курс прогрессі {{percent}}%',
          },
          ChangePassword: {
            ForgotPassword: 'Құпия сөзді ұмытып қалдыңыз ба?',
            Password: 'Құпия сөз',
            ChangePassword: 'Құпия сөзді өзгерту',
            OldPassword: 'Бұрынғы құпия сөз',
            NewPassword: 'Жаңа құпия сөз',
            NewPasswordRepeat: 'Жаңа құпия сөзді растау',
            SuccessPasswordChange: 'Құпия сөз өзгертілді'
          },
          ConfirmEmail: {
            ConfirmEmail: 'Поштаны растау',
            SuccessConfirmEmail: 'Пошта сәтті расталды! 5 минут ішінде сізге логин туралы мәліметтер жіберіледі',
            WrongLink: 'Дұрыс емес сілтеме',
            ToMain: 'Басты бетке'
          },
          ResetPassword: {
            ResetPassword: 'Құпия сөзді қалпына келтіру',
          },
          ConfirmResetPassword: {
            ConfirmResetPassword: 'Құпия сөзді қалпына келтіруді растау',
            SuccessConfirmResetPassword: 'Құпия сөзіңізді қалпына келтіруге сілтеме электрондық поштаңызға 5 минут ішінде жіберіледі'
          },
          SetNewPassword: {
            SetNewPassword: 'Жаңа құпия сөзді орнату',
            ConfirmNewPassword: 'Жаға құпия сөзді растаңыз',
            OldLink: 'Сілтеменің мерзімі аяқталды. Құпия сөзді қайта орнатуды сұрау'
          },
          ConfirmSetNewPassword: {
            ConfirmSetNewPassword: 'Жаңа құпия сөз параметрін растау',
            SuccessConfirmSetNewPassword: 'Құпия сөзіңіз сәтті өзгертілді. Жаңа құпия сөзбен кіруге болады'
          },
          Errors: {
            SignInError: 'Қате логин немесе құпия сөз',
            Error: 'Қате',
            CommonError: 'Бірдеңе дұрыс болмады. Қате туралы бізге хабарлаңыз',
            NotFound: 'Бет табылмады',
            Forbidden: 'Қосылуға мүмкіндік жоқ',
            ForbiddenMsg: 'Кешіріңіз, сізде бұл бетке кіру рұқсаты жоқ',
            ServerError: 'Ішкі қате',
            ServerErrorMsg: 'Бірдеңе дұрыс болмады. Қатені түзетіп жатырмыз'
          },
          SignUp: {
            SignUp: 'Тіркелу',
            Citizenship: 'Азаматтық',
            KZ: 'Қазақстан',
            Other: 'Басқа',
            Name: 'Аты',
            LastName: 'Тегі',
            Iin: 'ЖСН',
            IAgree: 'Мен жеке деректерді өңдеуге және сақтауға келісемін',
            Success: '5 минут ішінде электрондық поштаңызды растау сілтемесі электрондық пошта мекенжайыңызға жіберіледі'
          },
          User: {
            RequestDate: 'Өтініш толтыру күні',
            Course: 'Курс',
            Status: 'Күй',
            Approved: 'Бекітілді ',
            Rejected: 'Қабылданбады',
            Review: 'Қарастырылуда',
            New: 'Жаңа',
            UserProfile: 'Пайдаланушы профилі',
            PersonalInfo: 'Жекке анықтама',
            Phone: 'Телефон',
            Company: 'Компания',
            Position: 'Қызымет атауы',
            Address: 'Мекен жай',
            Language: 'Тіл',
            Apply: 'Қолдану',
            Document: 'Құжат',
            DocumentNumber: 'Жеке куәлік/төлқұжат нөмірі',
            BirthDate: 'Туған күн',
            DocumentIssuingAuthority: 'Жеке куәлік/төлқұжат берген мекеме ',
            DocumentExpDate: 'Жеке куәлік/төлқұжат аяқталу мерзімі',
            CourseRequests: 'Курсқа өтініш',
            MyCourses: 'Менің курстарым',
            ChangesApplied: 'Өзгерістер қолданылды'
          },
          Main: {
            Title: 'Шектеусіз үйреніңіз',
            Maintext: '«Білім алу кездейсоқ алынған нәрсе емес. Ал сіз ұмтылатын нәрсені құмарлықпен жасайсыз.» Эбигейл Адамс',
            EducatedStudents: '{{amount}} адамды сәтті оқытылды',
            JoinForFree: 'Тегін қосылу',
            SolutionsForBusiness: 'Бизнес шешімдері',
            Companies: 'Біздің табысты серіктестігіміз',
            PopularCourses: 'Танымал курстар',
            NewCourses: 'Жаңа курстар',
            FreeCourses: 'Тегін курстар',
            BusinessTitle: 'Бизнес шешімдері',
            EmailPlaceholder: 'Сіздің email',
            PhonePlaceholder: 'Сіздің телефон нөміріңіз +7XXXXXXXXXX форматында',
            NamePlaceholder: 'Сіздің атыңыз',
            CompanyPlaceholder: 'Компания Атауы',
            Submit: 'Жіберу',
            EmailInvalid: 'Жарамды электрондық поштаны енгізіңіз',
            PhoneInvalid: 'Жарамды телефон нөмірін енгізіңіз',
            SuccessRequest: 'Сұрауыңыз сәтті жіберілді! Менеджер сізге жақын арада хабарласады',
            BusinessText: 'Сіз компания атынан өтініш бере аласыз, бұл жағдайда тыңдаушылардың санына байланысты жеңілдік беріледі:',
            Business5Percent: '15 тен 20 адамға дейін - 5% жеңілдік,',
            Business10Percent: '20 адамнан дан 50 адамға дейін - 10% жеңілдік,',
            Business15Percent: '50 адамнан жоғары - 15% жеңілдік'
          },
          Yes: 'Иә',
          No: 'Жоқ'
        }
      }
    }
  });

export default i18n;
import styles from './style.module.css'
import { Icons, Button } from '../index'
import { Card, Badge } from 'react-bootstrap';
import './col.css'
import { useTranslation } from 'react-i18next';

const ExamCard = ({
  id,
  name,
  type,
  status,
  count_of_available_attempts,
  duration,
  percent_of_right_answers,
  finish_dt,
  pass_percent,
  setModalIsOpen,
  setClickId,
  history
}) => {

  const { t } = useTranslation();

  let completionTimetext = ''
  let sum_completion_time = Number(duration)
  if (0 <= sum_completion_time && sum_completion_time < 60) {
    completionTimetext = `${sum_completion_time} ${t('Time.Sec')}.`
  }
  else if (60 <= sum_completion_time && sum_completion_time < 3600) {
    completionTimetext = `${(sum_completion_time / 60).toFixed(0)} ${t('Time.Minute')}.`
  }
  else {
    completionTimetext = `${(sum_completion_time / 3600).toFixed(1)} ${t('Time.Hour')}.`
  }

  let badgeColor;
  let badgeText;
  switch (status) {
    case 'NEW':
      badgeColor = 'secondary'
      badgeText = t('Status.New')
      break;
    case 'PROGRESS':
      badgeColor = 'warning'
      badgeText = t('Status.Progress')
      break;
    case 'FAIL':
      badgeColor = 'danger'
      badgeText = t('Status.Fail')
      break;
    case 'PASS':
      badgeColor = 'success'
      badgeText = t('Status.Pass')
      break;
    default:
      badgeColor = 'secondary'
      badgeText = t('Status.New')
  }

  const handleClick = () => {
    setModalIsOpen(true)
    setClickId(id)
  }

  const toExam = () => {
    history.push(`/exams/${id}`)
  }

  const toResult = () => {
    history.push(`/exams/${id}/result`)
  }

  const getButton = () => {
    if (status == 'PROGRESS') {
      return <Button variant="primary" clickHandler={toExam}>{t('ExamCard.Continue')}</Button>
    }
    else if (count_of_available_attempts == 0) {
      if (type == 'TEST' && (status == 'FAIL' || status == 'PASS') && finish_dt !== null) {
        return <Button variant="primary" clickHandler={toResult}>{t('ExamCard.CheckErrors')}</Button>
      }
    }
    else {
      return <Button variant="primary" clickHandler={handleClick}>{t('ExamCard.Start')}</Button>
    }
  }

  return (
    <Card>
      <Card.Header as="h5">{name}</Card.Header>
      <Card.Body>
        <Card.Title></Card.Title>
        <Card.Text>
          <div className={styles.exam_text}>
            <span className={styles.card__highlight}>{t('ExamCard.AvailableAttempts')}: </span>{count_of_available_attempts}
          </div>
          <div className={styles.exam_text}>
            <span className={styles.card__highlight}>{t('ExamCard.Type')}: </span>{type == 'TEST' ? t('ExamCard.TestType') : t('ExamCard.RealType')}
          </div>
          <div className={styles.exam_text}>
            <Icons.ClockIcon /> {completionTimetext}
          </div>
          <div className={styles.exam_text}>
            <Badge bg={badgeColor}>{badgeText}</Badge>
          </div>
          {finish_dt !== null &&
            <div className={styles.exam_text}>
              <span className={styles.card__highlight}>{t('ExamCard.PercentOfCorrect')}: </span>{percent_of_right_answers} %
            </div>
          }
            <div className={styles.exam_text}>
              <span className={styles.card__highlight}>{t('ExamCard.PassPercent')}: </span>{pass_percent} %
            </div>
        </Card.Text>
      </Card.Body>
      <div className={styles.exam_button}>
        {getButton()}
      </div>
    </Card>
  )
}

export default ExamCard
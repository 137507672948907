import { Container, Main, Title, CustomToast, StudySessionNavigation } from '../../components'
import { Badge, Breadcrumb } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { useParams, useLocation } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import ReactPlayer from 'react-player'
import api from '../../api'
import { useTranslation } from 'react-i18next'
import queryString from 'query-string';

const SingleVideoLesson = ({ history }) => {

  const [ videoLesson, setVideoLesson ] = useState({})
  const [ studySession, setStudySession ] = useState(null)
  const [ lessonNeighbours, setLessonNeighbours ] = useState({})
  const [ apiError, setApiError ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(true)

  const { t } = useTranslation();
  const { id, study_session_id } = useParams();
  const location = useLocation();
  const searchParams = queryString.parse(location.search);

  const getVideoLesson = async (id) => {
    try {
      const response = await api.getVideoLesson({ id })
      setVideoLesson(response)
    }
    catch (error) {
      console.error(error)
    }
  }

  const setVideoLessonCompleted = async () => {
    try {
      if (!studySession?.completed_video_lessons_ids.includes(Number(id))) {
        const response = await api.completeVideoLesson({ study_session_id, id })
        setStudySession(response)
      }
    }
    catch (error) {
      console.error(error)
      setApiError(true)
    }
  }

  const getStudySession = async (id) => {
    try {
      const response = await api.getStudySession({ id })
      setStudySession(response)
    }
    catch (error) {
      console.error(error)
    }
  }

  const getLessonNeighbours = async (study_session_id, id, module_id) => {
    try {
      const response = await api.getLessonNeighbours({study_session_id: study_session_id, lesson_id: id, lesson_type: 'video_lessons', module_id: module_id})
      setLessonNeighbours(response)
    }
    catch (error) {
      console.error(error)
    }
  }

  const fetchData = async () => {
    setIsLoading(true);
    await getVideoLesson(id);
    await getStudySession(study_session_id);
    await getLessonNeighbours(study_session_id, id, searchParams.module_id);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [])

  const config = {
    file: {
      attributes: {
        controlsList: "nodownload"
      }
    }
  };
  
  return <Main>
    <CustomToast
      headerText={t('Errors.Error')}
      bodyText={t('Errors.CommonError')}
      onClose={() => setApiError(false)}
      show={apiError}
      delay={3000}
    />
    <Container>
      <MetaTags>
        <title>{videoLesson.name}</title>
        <meta name="description" content={`Учебная платформа STIA - ${videoLesson.name}`} />
        <meta property="og:title" content={videoLesson.name} />
      </MetaTags>
      <div className={styles.content}>
        <Title
          title={videoLesson.name}
          badge={studySession?.completed_video_lessons_ids.includes(Number(id)) && <Badge bg='success'>{t('StudySessionCard.Completed')}</Badge>}
        />
        <Breadcrumb>
          <Breadcrumb.Item href={'/profile'}>{t('Header.Profile')}</Breadcrumb.Item>
          <Breadcrumb.Item href={`/study_sessions/${study_session_id}`}>{t('CourseCard.Course')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{videoLesson.name}</Breadcrumb.Item>
        </Breadcrumb>
        <div className={styles.content_text}>
            <div dangerouslySetInnerHTML={{ __html: videoLesson.content }} />
        </div>
        {videoLesson.file &&
          <div className={styles.video}>
            <ReactPlayer
              url={videoLesson.file}
              controls={true}
              config={config}
              onEnded={setVideoLessonCompleted}
              width="70%"
              height="70%"
            />
          </div>
        }
        {videoLesson.url &&
          <a
            href={videoLesson.url}
            target='_blank'
            onClick={setVideoLessonCompleted}
          >
            {videoLesson.name}
          </a>
        }
        <StudySessionNavigation prevLesson={lessonNeighbours.prev} studySession={studySession} nextLesson={lessonNeighbours.next} history={history} currentLessonType={'video_lessons'}/>
      </div>
    </Container>
  </Main>
}

export default SingleVideoLesson


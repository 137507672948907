import { CourseCard, Title, Pagination, CardList, Container, Main, ModalComponent, CustomToast, Loader } from '../../components'
import styles from './styles.module.css'
import { useEffect, useState, useContext } from 'react'
import api from '../../api'
import MetaTags from 'react-meta-tags'
import { AuthContext } from '../../contexts'
import Select from 'react-select'
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'

const Courses = ({ history }) => {
const authContext = useContext(AuthContext)
const { t, i18n } = useTranslation()
const [ courses, setCourses] = useState([])
const [ coursesCount, setCoursesCount ] = useState(0)
const [ page, setPage ] = useState(1)
const [ courseTypes, setCourseTypes ] = useState([])
const [ selectedIds, setSelectedIds ] = useState([])
const [ searchTerm, setSearchTerm ] = useState('')
const [ modalIsOpen, setModalIsOpen ] = useState(false)
const [ apiError, setApiError ] = useState(false);
const [ isLoading, setIsLoading ] = useState(true);

const getCourses = async () => {
  try {
    const userLanguage = i18n.language;
    const { results, count } = await api.getCourses({ page, selectedIds, searchTerm, userLanguage });
    setCourses(results)
    setCoursesCount(count)
  }
  catch (error) {
    console.error(error)
    setApiError(true);
  }
}

const getCourseTypes = async () => {
  try {
    const userLanguage = i18n.language;
    const response = await api.getCourseTypes({ userLanguage });
    setCourseTypes(response)
  }
  catch (error) {
    console.error(error)
  }
}

const createCourseRequest = async (course) => {
  if (authContext === true) {
    try {
      const response = await api.createCourseRequest({ course })
      setModalIsOpen(true);
      getCourses();
    }
    catch (error) {
      console.error(error)
      setApiError(true);
    }
  }
  else {
    history.push('/signup');
  }
}

const handleCloseModal = () => {
  setModalIsOpen(false);
}

const onChangeCourseTypeFilter = async (selectedOptions) => {
  setSelectedIds(selectedOptions.map(option => option.id))
}

const onChangeCourseName = async (event) => {
  setSearchTerm(event.target.value)
}

const fetchData = async () => {
  setIsLoading(true);
  await Promise.all([getCourses(), getCourseTypes()])
  setIsLoading(false);
}

useEffect(() => {
  fetchData();
}, [page, selectedIds, searchTerm, i18n.language])

  return (isLoading ? <Loader /> :
    <Main>
      <CustomToast
        headerText={t('Errors.Error')}
        bodyText={t('Errors.CommonError')}
        onClose={() => setApiError(false)}
        show={apiError}
        delay={3000}
      />
      <Container>
        <MetaTags>
          <title>{t('CourseCard.CourseList')}</title>
          <meta name="description" content="Учебная платформа STIA - Список курсов" />
          <meta property="og:title" content={t('CourseCard.CourseList')} />
        </MetaTags>
        <div className={styles.title}>
          <Title title={t('CourseCard.CourseList')} />
        </div>
        <Row className={styles.filters}>
          <Col xs={4}>
            <Select
              options={courseTypes}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              onChange={onChangeCourseTypeFilter}
              placeholder={t('CourseCard.CourseType')} 
              isMulti
              className={styles.select}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  boxShadow: 'none',
                  borderColor: state.isFocused ? '#4A61DD' : '#c2c2c2',
                  "&:hover": {
                    border: "1px solid #4A61DD",
                    boxShadow: "0px 0px 2px #4A61DD"
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused ? '##92a0ec' : 'white',
                  ':hover': {
                    backgroundColor: '#92a0ec'
                  }
                })
              }}
            />
          </Col>
          <Col xs={4} className="d-flex justify-content-center">
            {/* Empty space between the first and last elements */}
          </Col>
          <Col xs={4} className="d-flex justify-content-end">
            <input 
              placeholder={t('CourseCard.SearchByName')} 
              onChange={onChangeCourseName}
              className={styles.search_bar}
            />
          </Col>
        </Row>
        <CardList>
          {courses.map(card => <CourseCard
            {...card}
            key={card.id}
            handleButtonClick={() => createCourseRequest(card.id)}
          />)}
        </CardList>
        <Pagination
          count={coursesCount}
          limit={3}
          page={page}
          onPageChange={page => setPage(page)}
        />
      </Container>
      <ModalComponent
        modalIsOpen={modalIsOpen}
        handleOkModal={handleCloseModal}
        handleCloseModal={handleCloseModal}
        modalTitle={t('CourseCard.SuccessCourseRequest')} 
      />
    </Main>
  )
}

export default Courses
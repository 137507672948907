import React, { useState, useEffect } from 'react';
import { Card, Form, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ExamQuestion = ({ examId, question, submitHandler, isResult }) => {

  const [selectedOption, setSelectedOption] = useState(null);
  const { t } = useTranslation();

  const { answers, user_answer } = question

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    submitHandler(examId, question.id, event.target.value, user_answer);
  };

  useEffect(() => {
    if (question?.user_answer) {
        setSelectedOption(question.user_answer.answer);
    }
  }, [question, ])

  const getAnswerLabel = (answer) => {
    if (!isResult) {
      return answer.name
    }
    else {
      if (answer.id == question.correct_answer.id) {
        return <>{answer.name} <Badge bg='success'>{t('ExamCard.CorrectAnswer')}</Badge></>
      }
      else {
        return answer.name
      }
    }
  }

  const isOptionSelected = (id) => {
    if (id == selectedOption) {
        return true
    }
    return false
  }

  return (
    <Card>
      <Card.Body>
        <Card.Text>{question.text}</Card.Text>
        <Form>
          <Form.Group>
            {answers.map((answer) => {
              return (
                <Form.Check
                  type="checkbox"
                  label={getAnswerLabel(answer)}
                  value={answer.id}
                  id={answer.id}
                  checked={isOptionSelected(answer.id)}
                  onChange={handleOptionChange}
                //   onClick={handleOptionChange}
                  disabled={isResult}
                />
              )
            })}
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ExamQuestion;
import { Container, Main, Title, PdfViewer, Question, CustomToast, Loader, StudySessionNavigation } from '../../components'
import { useState, useEffect } from 'react'
import { Accordion, Badge, Breadcrumb } from 'react-bootstrap'
import styles from './styles.module.css'
import { useParams, useLocation } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import ReactPlayer from 'react-player'
import api from '../../api'
import { useTranslation } from 'react-i18next'
import queryString from "query-string"


const SinglePracticalTask = ({ history }) => {

  const [ practicalTask, setPracticalTask ] = useState({})
  const [ studySession, setStudySession ] = useState(null)
  const [ lessonNeighbours, setLessonNeighbours ] = useState({})
  const [ apiError, setApiError ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(true)

  const { id, study_session_id } = useParams()
  const location = useLocation();
  const searchParams = queryString.parse(location.search);
  const { t } = useTranslation()

  const getPracticalTask = async (id) => {
    try {
      const response = await api.getPracticalTask({ id })
      setPracticalTask(response)
    }
    catch (error) {
      console.error(error)
    }
  }

  const completePracticalTask = async () => {
    try {
      const response = await api.completePracticalTask({ study_session_id, id })
      setStudySession(response)
    }
    catch (error) {
      console.error(error)
      setApiError(true)
    }
  }

  const getStudySession = async (id) => {
    try {
      const response = await api.getStudySession({ id })
      setStudySession(response)
    }
    catch (error) {
      console.error(error)
    }
  }

  const getLessonNeighbours = async (study_session_id, id, module_id) => {
    try {
      const response = await api.getLessonNeighbours({study_session_id: study_session_id, lesson_id: id, lesson_type: 'practical_tasks', module_id: module_id})
      setLessonNeighbours(response)
    }
    catch (error) {
      console.error(error)
    }
  }

  const fetchData = async () => {
    setIsLoading(true);
    await getPracticalTask(id);
    await getStudySession(study_session_id);
    await getLessonNeighbours(study_session_id, id, searchParams.module_id);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [])

  const config = {
    file: {
      attributes: {
        controlsList: "nodownload"
      }
    }
  };
  
  return (isLoading ? <Loader /> :
    <Main>
      <CustomToast
        headerText={t('Errors.Error')}
        bodyText={t('Errors.CommonError')}
        onClose={() => setApiError(false)}
        show={apiError}
        delay={3000}
      />
      <Container>
        <MetaTags>
          <title>{practicalTask.name}</title>
          <meta name="description" content={`Учебная платформа STIA - ${practicalTask.name}`} />
          <meta property="og:title" content={practicalTask.name} />
        </MetaTags>
        <div className={styles.content}>
          <Title
            title={practicalTask.name}
            badge={studySession?.completed_practical_tasks_ids.includes(Number(id)) && <Badge bg='success'>{t('StudySessionCard.Completed')}</Badge>}
          />
          <Breadcrumb>
            <Breadcrumb.Item href={'/profile'}>{t('Header.Profile')}</Breadcrumb.Item>
            <Breadcrumb.Item href={`/study_sessions/${study_session_id}`}>{t('CourseCard.Course')}</Breadcrumb.Item>
            <Breadcrumb.Item active>{practicalTask.name}</Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.content_text}>
            <div dangerouslySetInnerHTML={{ __html: practicalTask.content }} />
          </div>
          <Accordion alwaysOpen className={styles.accordion}>
            {practicalTask.video_lesson &&
              <Accordion.Item eventKey={practicalTask.video_lesson.id + practicalTask.video_lesson.name}>
                <Accordion.Header>{practicalTask.video_lesson.name}</Accordion.Header>
                <Accordion.Body>
                    <div dangerouslySetInnerHTML={{ __html: practicalTask?.videoLesson?.content }} />
                  <ReactPlayer
                    url={practicalTask.video_lesson.file}
                    controls={true}
                    config={config}
                  />
                </Accordion.Body>
              </Accordion.Item>
            }
            {practicalTask.external_document_lesson &&
              <Accordion.Item eventKey={practicalTask.external_document_lesson.id + practicalTask.external_document_lesson.name}>
                <Accordion.Header>{practicalTask.external_document_lesson.name}</Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: practicalTask?.external_document_lesson.content }} />
                  </div>
                  {practicalTask.external_document_lesson.urls.map((url) => {
                    return (
                      <a
                        href={url}
                        target='_blank'
                      >
                        {url}
                      </a>
                    )
                  })}
                </Accordion.Body>
              </Accordion.Item>
            }
            {practicalTask.internal_document_lesson &&
              <Accordion.Item eventKey={practicalTask.internal_document_lesson.id + practicalTask.internal_document_lesson.name}>
                <Accordion.Header>{practicalTask.internal_document_lesson.name}</Accordion.Header>
                <Accordion.Body>
                    <div dangerouslySetInnerHTML={{ __html: practicalTask?.internal_document_lesson.content }} />
                  <PdfViewer fileUrl={practicalTask.internal_document_lesson.file} />
                </Accordion.Body>
              </Accordion.Item>
            }
          </Accordion>
          {practicalTask.question &&
            <div className={styles.question_wrapper}>
              <Question
                question={practicalTask.question}
                submitHandler={completePracticalTask}
              />
            </div>
          }
          <StudySessionNavigation prevLesson={lessonNeighbours.prev} studySession={studySession} nextLesson={lessonNeighbours.next} history={history} currentLessonType={'practical_tasks'}/>
        </div>
      </Container>
    </Main>
  )
}

export default SinglePracticalTask


import { Container, Input, Title, Main, Form, Button, ModalComponent } from '../../components'
import styles from './styles.module.css'
import { useFormWithValidation } from '../../utils'
import api from '../../api'
import { useState } from 'react'
import MetaTags from 'react-meta-tags'
import { useTranslation } from 'react-i18next'

const ChangePassword = ({ history }) => {
  const { values, handleChange, errors, isValid, resetForm } = useFormWithValidation()
  const { t } = useTranslation();
  const [ formErrors, setFormErrors ] = useState({})
  const [ modalIsOpen, setModalIsOpen ] = useState(false);

  const changePassword = ({
    current_password,
    new_password,
    new_password_repeat
  }) => {
    api.changePassword({ current_password, new_password, new_password_repeat })
      .then(res => {
        setFormErrors({})
        setModalIsOpen(true);
      })
      .catch(err => {
        setFormErrors(err)
      })
  }

  const handleCloseModal = () => {
    setModalIsOpen(false);
  }

  return <Main>
    <Container>
      <MetaTags>
        <title>{t('ChangePassword.ChangePassword')}</title>
        <meta name="description" content="Учебная платформа STIA - Изменить пароль" />
        <meta property="og:title" content="Изменить пароль" />
      </MetaTags>
      <Title title={t('ChangePassword.ChangePassword')} />
      <Form
        className={styles.form}
        onSubmit={e => {
          e.preventDefault()
          changePassword(values)
        }}
      >
        <Input
          required
          label={t('ChangePassword.OldPassword')}
          type='password'
          name='current_password'
          onChange={handleChange}
          error={formErrors.current_password}
          errorText={formErrors.current_password}
        />
        <Input
          required
          label={t('ChangePassword.NewPassword')}
          type='password'
          name='new_password'
          onChange={handleChange}
          error={formErrors.new_password}
          errorText={formErrors.new_password}
        />
        <Input
          required
          label={t('ChangePassword.NewPasswordRepeat')}
          type='password'
          name='new_password_repeat'
          onChange={handleChange}
          error={formErrors.new_password_repeat}
          errorText={formErrors.new_password_repeat}
        />
        <Button
          modifier='style_dark-blue'
          type='submit'
          disabled={!isValid || values.new_password !== values.new_password_repeat}
        >
          {t('ChangePassword.ChangePassword')}
        </Button>
      </Form>
    </Container>
    <ModalComponent
      modalIsOpen={modalIsOpen}
      handleOkModal={handleCloseModal}
      handleCloseModal={handleCloseModal}
      modalTitle={t('ChangePassword.SuccessPasswordChange')}
    />
  </Main>
}

export default ChangePassword

import {
  Title,
  Pagination,
  CardList,
  Button,
  Container,
  Main,
  Form,
  CustomSelect,
  Input,
  ModalComponent,
  StudySessionCard,
  LinkComponent,
  CustomToast,
  Loader

} from '../../components'
import styles from './styles.module.css'
import { useEffect, useState, useContext, useMemo } from 'react'
import api from '../../api'
import { useFormWithValidation } from '../../utils/index.js'
import { UserContext } from '../../contexts'
import MetaTags from 'react-meta-tags'
import { Tabs, Tab, Nav } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap/Table';
import { useTable } from 'react-table';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

const ProfilePage = ({ history }) => {
  const [ user, setUser ] = useState(null)
  const [ userFormErrors, setUserFormErrors ] = useState({})

  const [ document, setDocument ] = useState(null)
  const [ documentFormErrors, setDocumentFormErrors ] = useState({})

  const [ studySessions, setStudySessions ] = useState([])
  const [ studySessionsCount, setStudySessionsCount ] = useState(0)
  const [ studySessionsPage, setStudySessionsPage ] = useState(1)

  const [ courseRequests, setCourseRequests ] = useState([])
  const [ courseRequestsCount, setCourseRequestsCount ] = useState(0)
  const [ courseRequestsPage, setCourseRequestsPage ] = useState(1)

  const userContext = useContext(UserContext)
  const [ activeTab, setActiveTab ] = useState('PersonalInfo')
  const [ modalIsOpen, setModalIsOpen ] = useState(false)
  const { values, handleChange, errors, isValid, resetForm } = useFormWithValidation()

  const [ apiError, setApiError ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(true)

  const { t, i18n } = useTranslation();

  const location = useLocation()

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
    history.push(`/profile/${tab}/`);
  }

  const handleCloseModal = () => {
    setModalIsOpen(false)
  }

  const getTabFromUrl = () => {
    const path = location.pathname;
    const parts = path.split('/');
    const slicedParts = parts.slice(1, parts.length - 1)
    const lastPart = slicedParts[slicedParts.length - 1];
    return lastPart
  }

  const getUser = async () => {
    try {
      const response = await api.getUser()
      setUser(response)
    }
    catch (error) {
      console.error(error)
      setApiError(true)
    }
  }

  const updateUser = async ({ email, citizenship, iin, first_name, last_name, phone, company, position, address, language }) => {
    try {
      const response = await api.updateUser({ email, citizenship, iin, first_name, last_name, phone, company, position, address, language })
      if (response) {
        setUserFormErrors({})
        i18n.changeLanguage(language)
        setModalIsOpen(true)
      }
    }
    catch (errors) {
      setUserFormErrors(errors)
    }
  }

  const getDocument = async () => {
    try {
      const response = await api.getDocument()
      setDocument(response)
    }
    catch (error) {
      console.error(error)
      setApiError(true)
    }
  }

  const updateDocument = async ({ number, birth_date, issuing_authority, expiration_date}) => {
    try {
      const response = await api.updateDocument({ number, birth_date, issuing_authority, expiration_date})
      if (response) {
        setDocumentFormErrors({})
        setModalIsOpen(true)
      }
    }
    catch (errors) {
      setDocumentFormErrors(errors)
    }
  }

  const getCourseRequests = async (courseRequestsPage) => {
    try {
      const { results, count } = await api.getCourseRequests({ courseRequestsPage })
      setCourseRequests(results)
      setCourseRequestsCount(count)
    }
    catch (error) {
      console.error(error)
      setApiError(true)
    }
  }

  const getStudySessions = async (studySessionsPage) => {
    try {
      const { results, count } = await api.getStudySessions({ studySessionsPage })
      setStudySessions(results)
      setStudySessionsCount(count)
    }
    catch (error) {
      console.error(error)
      setApiError(true)
    }
  }

  const fetchData = async () => {
    setIsLoading(true);
    await getUser()
    await getDocument()
    if (userContext?.is_on_contract === false) {
      await getCourseRequests(courseRequestsPage);
    }
    setActiveTab(getTabFromUrl())
    setIsLoading(false);
  }

  useEffect(_ => {
    fetchData();
  }, [])

  useEffect(_ => {
    getStudySessions(studySessionsPage)
  }, [studySessionsPage, studySessionsCount])

  useEffect(_ => {
    getCourseRequests(courseRequestsPage)
  }, [courseRequestsPage, courseRequestsCount])

  const CourseRequestTable = ({ data }) => {
    const columns = useMemo(
      () => [
        {
          Header: t('User.RequestDate'),
          accessor: 'created',
          Cell: ({ row: {original} }) => {
            const humanReadableDate = new Date(original?.created).toLocaleString('ru-RU', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: false
            });
            return humanReadableDate
          }
        },
        {
          Header: t('User.Course'),
          accessor: 'course.name',
          Cell: ({ row: {original} }) => {
            return (
              <LinkComponent href={`courses/${original?.course?.id}`} title={original?.course?.name} className={styles.course_link}/>
            )
          }
        },
        {
          Header: t('User.Status'),
          accessor: 'status',
          Cell: ({ row: {original} }) => {
            let text = ''
            let className
            switch (original?.status) {
              case 'APPROVED':
                text = t('User.Approved')
                className = styles.status_approved
                break;
              case 'REJECTED':
                text = t('User.Rejected')
                className = styles.status_rejected
                break;
              case 'REVIEW':
                text = t('User.Review')
                className = styles.status_review
                break;
              case 'NEW':
                text = t('User.New')
                className = styles.status_new
                break;
              default:
                text = t('User.New')
            }
            return (
              <div className={className}>
                <span className={styles.course_request_status_text}>{text}</span>
              </div>
            )
          }
        },
      ],
      []
    );
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({ columns, data });
  
    return (
      <BootstrapTable striped bordered hover {...getTableProps()} className={styles.course_request_table}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </BootstrapTable>
    );
  };

  return (isLoading ? <Loader /> :
    <Main>
      <CustomToast
        headerText={t('Errors.Error')}
        bodyText={t('Errors.CommonError')}
        onClose={() => setApiError(false)}
        show={apiError}
        delay={3000}
      />
      <Container className={styles.container}>
        <MetaTags>
          <title>{user ? `${user.first_name} ${user.last_name}` : t('User.UserProfile')}</title>
          <meta name="description" content={user ? `Учебная платформа STIA - ${user.first_name} ${user.last_name}` : 'Учебная платформа STIA - Профиль пользователя'} />
          <meta property="og:title" content={user ? `${user.first_name} ${user.last_name}` : t('User.UserProfile')} />
        </MetaTags>
        <div className={styles.title}>
          <Title
            title={t('Header.Profile')}
          />
        </div>
          <Tabs activeKey={activeTab} onSelect={handleTabSelect} defaultActiveKey={'personal_info'} justify variant='tabs'>
            <Tab eventKey="personal_info" title={<Nav.Link style={{ color: '#4A61DD' }}>{t('User.PersonalInfo')}</Nav.Link>}>
              <Form className={styles.form} onSubmit={e => {
                e.preventDefault()
                updateUser(values)
                getUser()
              }}>
                <Input
                  label='Email'
                  name='email'
                  required
                  onChange={handleChange}
                  value={user?.email}
                  error={userFormErrors?.email}
                  errorText={userFormErrors?.email}
                />
                <CustomSelect
                  label={t('SignUp.Citizenship')}
                  name='citizenship'
                  required
                  disabled={true}
                  onChange={handleChange}
                  options={
                    [
                      {
                        'value': 'KZ', 'label': t('SignUp.KZ')
                      },
                      {
                        'value': 'OTHER', 'label': t('SignUp.Other')
                      }
                    ]
                  }
                  value={user?.citizenship}
                />
                  <Input
                  label={t('SignUp.Iin')}
                  name='iin'
                  required
                  maxLength={12}
                  onChange={handleChange}
                  value={user?.iin}
                  error={userFormErrors?.iin}
                  errorText={userFormErrors?.iin}
                />
                <Input
                  label={t('SignUp.Name')}
                  name='first_name'
                  required
                  onChange={handleChange}
                  value={user?.first_name}
                  error={userFormErrors?.first_name}
                  errorText={userFormErrors?.first_name}
                />
                <Input
                  label={t('SignUp.LastName')}
                  name='last_name'
                  required
                  onChange={handleChange}
                  value={user?.last_name}
                  error={userFormErrors?.last_name}
                  errorText={userFormErrors?.last_name}
                />
                <Input
                  label={t('User.Phone')}
                  name='phone'
                  onChange={handleChange}
                  value={user?.phone}
                  error={userFormErrors?.phone}
                  errorText={userFormErrors?.phone}
                />
                <Input
                  label={t('User.Company')}
                  name='company'
                  onChange={handleChange}
                  value={user?.company}
                  error={userFormErrors?.company}
                  errorText={userFormErrors?.company}
                />
                <Input
                  label={t('User.Position')}
                  name='position'
                  onChange={handleChange}
                  value={user?.position}
                  error={userFormErrors?.position}
                  errorText={userFormErrors?.position}
                />
                <Input
                  label={t('User.Address')}
                  name='address'
                  onChange={handleChange}
                  value={user?.address}
                  error={userFormErrors?.address}
                  errorText={userFormErrors?.address}
                />
                <CustomSelect
                  label={t('User.Language')}
                  name='language'
                  required
                  onChange={handleChange}
                  options={
                    [
                      {
                        'value': 'kk', 'label': 'Қазақ'
                      },
                      {
                        'value': 'ru', 'label': 'Русский'
                      },
                      {
                        'value': 'en', 'label': 'English'
                      }
                    ]
                  }
                  value={user?.language}
                  error={userFormErrors?.language}
                  errorText={userFormErrors?.language}
                />
                <Button
                  modifier='style_dark-blue'
                  type='submit'
                  className={styles.button}
                  disabled={!isValid}
                >
                  {t('User.Apply')}
                </Button>
              </Form>
            </Tab>
            <Tab eventKey="document" title={<Nav.Link style={{ color: '#4A61DD' }}>{t('User.Document')}</Nav.Link>}>
              <Form className={styles.form} onSubmit={e => {
                e.preventDefault()
                updateDocument(values)
                getDocument()
              }}>
                <Input
                  label={t('User.DocumentNumber')}
                  name='number'
                  required
                  onChange={handleChange}
                  value={document?.number}
                  maxLength={12}
                  error={documentFormErrors?.number}
                  errorText={documentFormErrors?.number}
                />
                <Input
                  label={t('User.BirthDate')}
                  name='birth_date'
                  type='date'
                  onChange={handleChange}
                  value={document?.birth_date}
                  error={documentFormErrors?.birth_date}
                  errorText={documentFormErrors?.birth_date}
                />
                <Input
                  label={t('User.DocumentIssuingAuthority')}
                  name='issuing_authority'
                  onChange={handleChange}
                  value={document?.issuing_authority}
                  error={documentFormErrors?.issuing_authority}
                  errorText={documentFormErrors?.issuing_authority}
                />
                <Input
                  label={t('User.DocumentExpDate')}
                  type='date'
                  name='expiration_date'
                  onChange={handleChange}
                  value={document?.expiration_date}
                  error={documentFormErrors?.expiration_date}
                  errorText={documentFormErrors?.expiration_date}
                />
                <Button
                  modifier='style_dark-blue'
                  type='submit'
                  className={styles.button}
                  disabled={!isValid}
                >
                  {t('User.Apply')}
                </Button>
              </Form>
            </Tab>
            {userContext?.is_on_contract === false &&
              <Tab eventKey="course_requests" title={<Nav.Link style={{ color: '#4A61DD' }}>{t('User.CourseRequests')}</Nav.Link>}>
                <CourseRequestTable data={courseRequests} />
                <Pagination
                  count={courseRequestsCount}
                  limit={3}
                  page={courseRequestsPage}
                  onPageChange={page => setCourseRequestsPage(page)}
                />
              </Tab>
            }
            <Tab eventKey="study_sessions" title={<Nav.Link style={{ color: '#4A61DD' }}>{t('User.MyCourses')}</Nav.Link>}>
              {studySessions && 
                <div className={styles.card_list}>
                  <CardList>
                    {studySessions.map(card => <StudySessionCard
                      {...card}
                      key={card.id}
                    />)}
                  </CardList>
                  <Pagination
                    count={studySessionsCount}
                    limit={3}
                    page={studySessionsPage}
                    onPageChange={page => setStudySessionsPage(page)}
                  />
                </div>
              }
            </Tab>
          </Tabs>
          <ModalComponent
            modalIsOpen={modalIsOpen}
            handleOkModal={handleCloseModal}
            handleCloseModal={handleCloseModal}
            modalTitle={t('User.ChangesApplied')}
          />
      </Container>
    </Main>
  )
}

export default ProfilePage


import Button from "../../button";
import styles from './style.module.css'
import { Row, Col, Badge } from 'react-bootstrap';
import { Icons } from "../..";
import { useTranslation } from "react-i18next";

function ShortLessonCard(props) {
  const { lesson, is_done, url, study_session_id, history, module } = props
  const { t } = useTranslation();
  let completionTimetext = ''
  let sum_completion_time = Number(lesson.completion_time)
  if (0 <= sum_completion_time && sum_completion_time < 60) {
    completionTimetext = `${sum_completion_time} ${t('Time.Sec')}.`
  }
  else if (60 <= sum_completion_time && sum_completion_time < 3600) {
    completionTimetext = `${(sum_completion_time / 60).toFixed(0)} ${t('Time.Minute')}.`
  }
  else {
    completionTimetext = `${(sum_completion_time / 3600).toFixed(1)} ${t('Time.Hour')}.`
  }

  const onButtonClick = () => {
    history.push(`/study_sessions/${study_session_id}/${url}/${lesson.id}?module_id=${module.id}`)
  }

  return (
    <div className={styles.card}>
      <Row>
        <Col xs={8}>
          <div className={styles.lesson_info}>
            <div className={styles.lesson_name}>{lesson.name} {is_done && <Badge bg='success'>{t('Lesson.Completed')}</Badge>}</div>
            <div className={styles.lesson_description}>{lesson.description}</div>
            <div className={styles.lesson_time}><Icons.ClockIcon />{completionTimetext}</div>
          </div>
        </Col>
        <Col xs={4} className="d-flex align-items-center justify-content-end">
          <Button clickHandler={onButtonClick}>{t('Lesson.Start')}</Button>
        </Col>
      </Row>
    </div>
  );
}

export default ShortLessonCard;
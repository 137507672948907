import { Container, Title, Main, LinkComponent, CustomToast } from '../../components'
import styles from './styles.module.css'
import { useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import MetaTags from 'react-meta-tags'
import api from '../../api'
import { useTranslation } from 'react-i18next'

const ConfirmEmail = () => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const { t } = useTranslation();

  const [ emailIsConfirmed, setEmailIsConfirmed ] = useState(null)
  const [ apiError, setApiError ] = useState(false)

  const confirmEmail = async () => {
    try {
      const response = await api.confirmEmail({ token })
      if (response?.is_confirmed === true) {
        setEmailIsConfirmed(true);
      }
      else {
        setEmailIsConfirmed(false);
      }
    }
    catch (error) {
      setEmailIsConfirmed(false);
      setApiError(true);
    }
  }

  useEffect(() => {
    confirmEmail();
  }, []);

  return <Main>
    <CustomToast
      headerText={t('Errors.Error')}
      bodyText={t('Errors.CommonError')}
      onClose={() => setApiError(false)}
      show={apiError}
      delay={3000}
    />
    <Container>
      <MetaTags>
        <title>{t('ConfirmEmail.ConfirmEmail')}</title>
        <meta name="description" content="Учебная платформа STIA - подтверждение почты" />
        <meta property="og:title" content={t('ConfirmEmail.ConfirmEmail')} />
      </MetaTags>
      <Title title={t('ConfirmEmail.ConfirmEmail')} />
      {emailIsConfirmed && emailIsConfirmed !== null &&
        <>
          <div className={styles.text}>
          {t('ConfirmEmail.SuccessConfirmEmail')}
          </div>
          <LinkComponent
            href='/main'
            title={t('ConfirmEmail.ToMain')}
            className={styles.menuButton}
          />
        </>
    }
    {!emailIsConfirmed && emailIsConfirmed !== null &&
      <>
        <div className={styles.text}>
          {t('ConfirmEmail.WrongLink')}
        </div>
        <LinkComponent
            href='/main'
            title={t('ConfirmEmail.ToMain')}
            className={styles.menuButton}
        />
      </>
    }
    </Container>
  </Main>
}

export default ConfirmEmail

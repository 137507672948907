import { Toast } from 'react-bootstrap'


const CustomToast = ({ headerText, bodyText, onClose, show, delay }) => {
  return (
    <div
      style={{
        position: 'fixed',
        minHeight: '100px',
        top: 10,
        right: 10,
        zIndex: 9999
      }}
    >
      <Toast
        style={{
          position: 'realtive',
          top: 0,
          right: 0,
        }}
        onClose={onClose}
        show={show}
        delay={delay}
        autohide
      >
        <Toast.Header>
          <strong className="mr-auto">{headerText}</strong>
        </Toast.Header>
        <Toast.Body>{bodyText}</Toast.Body>
      </Toast>
    </div>
  )
}

export default CustomToast

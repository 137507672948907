import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom'
import Button from '../button';

const Question = ({ question, submitHandler }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showAnswer, setShowAnswer] = useState(false);

  const { id, study_session_id } = useParams();

  const { answers, correct_answer } = question

  const handleOptionChange = (event) => {
    setShowAnswer(false)
    const option = Number(event.target.value);
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleSubmit = () => {
    submitHandler();
    setShowAnswer(true);
  };

  const isOptionSelected = (option) => {
    return selectedOptions.includes(option);
  };

  const isOptionCorrect = (option) => {
    return correct_answer.id == option;
  };

  const getOptionColor = (option) => {
    if (showAnswer) {
      if (isOptionSelected(option)) {
        return isOptionCorrect(option) ? 'text-success' : 'text-danger';
      }
      return isOptionCorrect(option) ? 'text-success' : '';
    }
    return '';
  };

  return (
    <Card>
      <Card.Body>
        <Card.Text>{question.text}</Card.Text>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            {answers.map((answer) => {
              return (
                <Form.Check
                  type="checkbox"
                  label={answer.name}
                  value={answer.id}
                  id={answer.id}
                  checked={isOptionSelected(answer.id)}
                  onChange={handleOptionChange}
                  className={getOptionColor(answer.id)}
                />
              )
            })}
          </Form.Group>
          <Button children={'Проверить'} clickHandler={handleSubmit} type='button'/>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default Question;
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Button from '../button';
import Input from '../input';
import styles from './styles.module.css';
import pdfWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { useTranslation } from 'react-i18next';


function PdfViewer({ fileUrl }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const { t } = useTranslation();

  function handleDocumentLoadSuccess({ numPages, width }) {
    setNumPages(numPages);
  }

  function handlePrevPage() {
    setPageNumber(pageNumber - 1);
  }

  function handleNextPage() {
    setPageNumber(pageNumber + 1);
  }

  function onChangeScale(event) {
    setScale(0.02 * event.target.value)
  }

  return (
    <>
      <Input label={'Масштаб'} type='range' onChange={onChangeScale} />
      <div className={styles['pdf-toolbar']}>
        <Button clickHandler={handlePrevPage} disabled={pageNumber <= 1}>{"<-"}</Button>
        <span>{t('Pagination.Page')} {pageNumber} {t('Pagination.Of')} {numPages}</span>
        <Button clickHandler={handleNextPage} disabled={pageNumber >= numPages}>{"->"}</Button>
      </div>
      <div className={styles['pdf-container']}>
        <Document file={fileUrl} onLoadSuccess={handleDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} className={styles['pdf-page']} scale={scale}/>
        </Document>
      </div>
    </>
  );
}

export default PdfViewer;

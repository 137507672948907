import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import './i18n.js';


Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_FRONTEND_URL,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["localhost", process.env.REACT_APP_API_URL],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: process.env.REACT_APP_SENTRY_FRONTEND_PERF_RATE,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

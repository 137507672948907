export default [
  {
    title_ru: 'Главная',
    title_en: 'Main',
    title_kk: 'Басты бет',
    href: '/main',
    auth: false
  },
  {
    title_ru: 'Список курсов',
    title_en: 'Courses list',
    title_kk: 'Курстар тізімі',
    href: '/courses',
    auth: false
  },
  {
    title_ru: 'Профиль',
    title_en: 'Profile',
    title_kk: 'Профиль',
    href: '/profile',
    auth: true
  }
]
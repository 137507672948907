import { Container, Main, Title, CustomToast, Loader, StudySessionNavigation } from '../../components'
import { Badge, Breadcrumb } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { useParams, useLocation } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import api from '../../api'
import { useTranslation } from 'react-i18next'
import queryString from "query-string"

const SingleExternalDocumentLesson = ({ history }) => {

  const [ externalDocumentLesson, setExternalDocumentLesson ] = useState(null)
  const [ studySession, setStudySession ] = useState(null)
  const [ lessonNeighbours, setLessonNeighbours ] = useState({});
  const [ apiError, setApiError ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);

  const { t } = useTranslation()
  const { id, study_session_id } = useParams()
  const location = useLocation();
  const searchParams = queryString.parse(location.search);

  const getExternalDocumentLesson = async (id) => {
    try {
      const response = await api.getExternalDocumentLesson({ id })
      setExternalDocumentLesson(response)
    }
    catch (error) {
      console.error(error)
    }
  }

  const setExternalDocumentLessonCompleted = async () => {
    try {
      const response = await api.completeExternalDocumentLesson({ study_session_id, id })
      setStudySession(response)
    }
    catch (error) {
      console.error(error)
      setApiError(true);
    }
  }

  const getStudySession = async (id) => {
    try {
      const response = await api.getStudySession({ id })
      setStudySession(response)
    }
    catch (error) {
      console.error(error)
    }
  }

  const getLessonNeighbours = async (study_session_id, id, module_id) => {
    try {
      const response = await api.getLessonNeighbours({study_session_id: study_session_id, lesson_id: id, lesson_type: 'external_document_lessons', module_id: module_id})
      setLessonNeighbours(response)
    }
    catch (error) {
      console.error(error)
    }
  }

  const fetchData = async () => {
    setIsLoading(true);
    await getExternalDocumentLesson(id);
    await getStudySession(study_session_id);
    await getLessonNeighbours(study_session_id, id, searchParams.module_id);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [id])
  
  return (isLoading ? <Loader /> :
    <Main>
      <CustomToast
        headerText={t('Errors.Error')}
        bodyText={t('Errors.CommonError')}
        onClose={() => setApiError(false)}
        show={apiError}
        delay={3000}
      />
      <Container>
        <MetaTags>
          <title>{externalDocumentLesson?.name}</title>
          <meta name="description" content={`Учебная платформа STIA - ${externalDocumentLesson?.name}`} />
          <meta property="og:title" content={externalDocumentLesson?.name} />
        </MetaTags>
        {externalDocumentLesson &&
          <div className={styles.content}>
            <Title
              title={externalDocumentLesson.name}
              badge={studySession?.completed_external_document_lessons_ids.includes(Number(id)) && <Badge bg='success'>{t('StudySessionCard.Completed')}</Badge>}
            />
            <Breadcrumb>
              <Breadcrumb.Item href={'/profile'}>{t('Header.Profile')}</Breadcrumb.Item>
              <Breadcrumb.Item href={`/study_sessions/${study_session_id}`}>{t('CourseCard.Course')}</Breadcrumb.Item>
              <Breadcrumb.Item active>{externalDocumentLesson.name}</Breadcrumb.Item>
            </Breadcrumb>
            <div className={styles.content_text}>
                <div dangerouslySetInnerHTML={{ __html: externalDocumentLesson.content }} />
            </div>
            {externalDocumentLesson.urls.map((url) => {
              return (
                  <a
                    href={url}
                    target='_blank'
                    onClick={setExternalDocumentLessonCompleted}
                  >
                    {url}
                  </a>
              )
            })}
            <StudySessionNavigation prevLesson={lessonNeighbours.prev} studySession={studySession} nextLesson={lessonNeighbours.next} history={history} currentLessonType={'external_document_lessons'}/>
          </div>
        }
      </Container>
    </Main>
  )
}

export default SingleExternalDocumentLesson


import Accordion from 'react-bootstrap/Accordion';

function Lessons(props) {
  const { lessons } = props
  return (
    <Accordion alwaysOpen>
      {lessons.map((lesson) => (
        <Accordion.Item eventKey={String(lesson.id) + lesson.name}>
          <Accordion.Header>{lesson.name}</Accordion.Header>
          <Accordion.Body>
            {lesson.description}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

export default Lessons;
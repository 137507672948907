import { Button, Container, Main, CourseCard, ModalComponent, Form, Input, CustomToast, Loader } from '../../components'
import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'
import { useState, useEffect, useContext, useRef } from 'react'
import { AuthContext, UserContext } from '../../contexts'
import MetaTags from 'react-meta-tags'
import { Row, Col } from 'react-bootstrap'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { useFormWithValidation } from '../../utils/index.js'
import { Pagination, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import mainImage from '../../images/main_image2.png'
import kazMunayGaz from '../../images/kazmunaygaz.svg.png'
import kazTransOil from '../../images/kaztransoil.svg.png'
import karchaganak from '../../images/karchaganak.png'
import honeywell from '../../images/honeywell.png'
import api from '../../api'
import './navigation.css'

const HomePage = ({ history }) => {

  const emailRegex = new RegExp(/^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i)
  const phoneRegex = new RegExp(/^[\d+]+$/)

  const [ courses, setCourses ] = useState([]);
  const [ popularCourses, setPopularCourses ] = useState([]);
  const [ newCourses, setNewCourses ] = useState([]);
  const [ freeCourses, setFreeCourses ] = useState([]);
  const [ modalIsOpen, setModalIsOpen ] = useState(false);
  const [ modalSuccessRequestIsOpen, setModalSuccessRequestIsOpen ] = useState(false);
  const { values, handleChange, errors, isValid, resetForm } = useFormWithValidation();
  const [ numberOfStudents, setNumberOfStudents ] = useState(300);
  const [ apiError, setApiError ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);

  const [ email, setEmail ] = useState("");
  const [ emailError, setEmailError ] = useState(false);
  const [ phone, setPhone ] = useState("");
  const [ phoneError, setPhoneError ] = useState(false);
  const [ name, setName ] = useState("");
  const [ companyName, setCompanyName ] = useState("");
  const [ disableSubmit, setDisableSubmit ] = useState(true);

  const authContext = useContext(AuthContext)

  const businessRef = useRef(null);

  const { t, i18n } = useTranslation()

  console.log(phoneError)

  const getCourses = async () => {
    try {
      const userLanguage = i18n.language;
      const results = await api.getCourses({ userLanguage });
      setCourses(results);
      setPopularCourses(results.slice(0, 10));
      setNewCourses(results.filter(course => course?.is_new === true).slice(0, 10));
      setFreeCourses(results.filter(course => course?.is_free === true).slice(0, 10));
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
      setApiError(true);
    }
  }

  const getNumberOfStudents = async () => {
    try {
      const result = await api.getNumberOfStudents();
      setNumberOfStudents(result?.number_of_students);
    }
    catch (error) {
      console.error(error);
      setApiError(true);
    }
  }

  const createCourseRequest = async (course) => {
    if (authContext === true) {
      try {
        const response = await api.createCourseRequest({ course })
        setModalIsOpen(true);
        getCourses();
      }
      catch (error) {
        console.error(error);
        setApiError(true);
      }
    }
    else {
      history.push('/signup');
    }
  }

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setModalSuccessRequestIsOpen(false);
  }

  const validateEmail = () => {
    if (emailRegex.test(values.email) === true) {
      setEmailError(false)
    }
    else {
      setEmailError(true)
    }
  }

  const validatePhone = () => {
    if (isValidPhoneNumber(values.phone, 'KZ') === true && (phoneRegex.test(values.phone) === true)) {
      setPhoneError(false)
    }
    else {
      setPhoneError(true)
    }
  }

  const submitForm = async (values) => {
    const {email, phone, name, companyName} = values
    if (email && phone && name && companyName && !emailError && !phoneError) {
      const cleanedPhone = phone.replaceAll('+', '')
      const userLanguage = i18n.language
      try {
        const response = await api.createBusinessRequest({ email, phone: cleanedPhone, name, company_name: companyName, language: userLanguage} )
        setModalSuccessRequestIsOpen(true)
      }
      catch (error) {
        console.error(error)
      }
    }
  }

  const fetchData = async () => {
    setIsLoading(true);
    await Promise.all([getCourses(), getNumberOfStudents()]);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [i18n.language, ])

  useEffect(() => {
    if (values.email && values.phone && values.name && values.companyName && !emailError && !phoneError) {
      setDisableSubmit(false)
    }
    else {
      setDisableSubmit(true)
    }
  }, [values, ])

  const breakpoints = {
    790: {
      slidesPerView: 3,
      spaceBetween: 50
    },
    500: {
      slidesPerView: 2,
      spaceBetween: 25
    }
  }

  return (isLoading ? <Loader /> :
    <Main>
      <CustomToast
        headerText={t('Errors.Error')}
        bodyText={t('Errors.CommonError')}
        onClose={() => setApiError(false)}
        show={apiError}
        delay={3000}
      />
      <Container>
        <MetaTags>
          <title>{t('Main.Title')}</title>
          <meta name="description" content="Учебная платформа STIA - Главная" />
          <meta property="og:title" content={t('Main.Title')} />
        </MetaTags>
        <div>
          <hr/>
          <Row>
            <Col xs={7}>
              <div className={styles.landing_text}>
                <div>
                  <h1 className={styles.title}>{t('Main.Title')}</h1>
                </div>
                <div className={styles.text}>
                  {t('Main.Maintext')}
                </div>
                <div className={styles.highlight}>
                  {t('Main.EducatedStudents', {amount: numberOfStudents})}
                </div>
              </div>
              <div className={styles.buttons}>
                <div>
                  <Button clickHandler={() => history.push('/signup')}>{t('Main.JoinForFree')}</Button>
                </div>
                <div>
                  <Button clickHandler={() => businessRef.current.scrollIntoView({behaviour: 'smooth', block: 'start'})}>
                    {t('Main.SolutionsForBusiness')}
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs={5}>
              <div className={styles.main_image_container}>
                <img src={mainImage} className={styles.main_image}/>
              </div>
            </Col>
          </Row>
          <div className={styles.companies_block}>
            <h2 className={styles.companies_title}>
              {t('Main.Companies')}
            </h2>
            <div className={styles.companies}>
              <img src={kazMunayGaz} className={styles.company__kazmunaygaz}/>
              <img src={honeywell} className={styles.company__honeywell}/>
              <img src={kazTransOil} className={styles.company__kaztransoil}/>
              <img src={karchaganak} className={styles.company__karchaganak}/>
            </div>
          </div>
          {popularCourses && <div className={styles.popular_courses}>
            <h2 className={styles.courses_title}>{t('Main.PopularCourses')}</h2>
            <Swiper
              modules={[Pagination, Navigation]}
              spaceBetween={15}
              slidesPerView={1}
              pagination={true}
              navigation={{
                nextEl: ".swiper-button-next-popular",
                prevEl: ".swiper-button-prev-popular"
              }}
              breakpoints={breakpoints}
            >
              <div className={styles.test}>
                {popularCourses && popularCourses.map(course => {
                  return (
                    <SwiperSlide key={course.id}>
                      <CourseCard
                        {...course}
                        key={course.id}
                        handleButtonClick={() => createCourseRequest(course.id)}
                      />
                    </SwiperSlide>
                  )
                })}
              </div>
              </Swiper>
              <div className="swiper-button-next-popular popular-right">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 100 100" version="1.1"><path d="" stroke="none" fill="#5064dc" fillRule="evenodd"/><path d="M 26.667 6.667 C 26.300 7.033, 26 8.492, 26 9.907 C 26 11.913, 30.133 16.619, 44.740 31.240 L 63.480 50 44.658 68.841 C 27.841 85.676, 25.872 87.993, 26.168 90.591 C 26.439 92.965, 27.035 93.561, 29.409 93.832 C 32.017 94.129, 34.479 92.006, 53.159 73.341 C 69.249 57.265, 74 51.944, 74 50 C 74 46.301, 33.614 6, 29.908 6 C 28.492 6, 27.033 6.300, 26.667 6.667" stroke="none" fill="#4c64dc" fillRule="evenodd"/></svg>
              </div>
              <div className="swiper-button-prev-popular popular-left">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 100 100" version="1.1"><path d="M 43.489 30.250 L 26.500 47.500 43.750 30.511 C 53.237 21.167, 61 13.405, 61 13.261 C 61 12.548, 59.273 14.224, 43.489 30.250 M 56 70.500 C 65.613 80.125, 73.704 88, 73.979 88 C 74.254 88, 66.613 80.125, 57 70.500 C 47.387 60.875, 39.296 53, 39.021 53 C 38.746 53, 46.387 60.875, 56 70.500" stroke="none" fill="#4c5cdc" fillRule="evenodd"/><path d="M 47.105 27.192 C 34.128 40.315, 26.027 49.258, 26.017 50.474 C 26.006 51.721, 33.676 60.123, 46.857 73.304 C 65.509 91.956, 68.019 94.126, 70.607 93.830 C 73.001 93.557, 73.500 93.035, 73.500 90.803 C 73.500 88.643, 69.781 84.357, 54.797 69.247 L 36.094 50.386 55.080 31.443 C 72.140 14.422, 74.037 12.196, 73.783 9.500 C 73.555 7.083, 72.982 6.440, 70.838 6.192 C 68.544 5.927, 65.259 8.834, 47.105 27.192" stroke="none" fill="#4c64dc" fillRule="evenodd"/></svg>
              </div>
          </div>}
          {newCourses && <div className={styles.new_courses}>
            <h2 className={styles.courses_title}>{t('Main.NewCourses')}</h2>
            <Swiper
              modules={[Pagination, Navigation]}
              spaceBetween={15}
              slidesPerView={1}
              pagination={true}
              navigation={{
                nextEl: ".swiper-button-next-new",
                prevEl: ".swiper-button-prev-new",
              }}
              breakpoints={breakpoints}
            >
              <div className={styles.test}>
                {newCourses && newCourses.map(course => {
                  return (
                    <SwiperSlide key={course.id}>
                      <CourseCard
                        {...course}
                        key={course.id}
                        handleButtonClick={() => createCourseRequest(course.id)}
                      />
                    </SwiperSlide>
                  )
                })}
              </div>
            </Swiper>
            <div className="swiper-button-next-new new-right">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 100 100" version="1.1"><path d="" stroke="none" fill="#5064dc" fillRule="evenodd"/><path d="M 26.667 6.667 C 26.300 7.033, 26 8.492, 26 9.907 C 26 11.913, 30.133 16.619, 44.740 31.240 L 63.480 50 44.658 68.841 C 27.841 85.676, 25.872 87.993, 26.168 90.591 C 26.439 92.965, 27.035 93.561, 29.409 93.832 C 32.017 94.129, 34.479 92.006, 53.159 73.341 C 69.249 57.265, 74 51.944, 74 50 C 74 46.301, 33.614 6, 29.908 6 C 28.492 6, 27.033 6.300, 26.667 6.667" stroke="none" fill="#4c64dc" fillRule="evenodd"/></svg>
              </div>
              <div className="swiper-button-prev-new new-left">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 100 100" version="1.1"><path d="M 43.489 30.250 L 26.500 47.500 43.750 30.511 C 53.237 21.167, 61 13.405, 61 13.261 C 61 12.548, 59.273 14.224, 43.489 30.250 M 56 70.500 C 65.613 80.125, 73.704 88, 73.979 88 C 74.254 88, 66.613 80.125, 57 70.500 C 47.387 60.875, 39.296 53, 39.021 53 C 38.746 53, 46.387 60.875, 56 70.500" stroke="none" fill="#4c5cdc" fillRule="evenodd"/><path d="M 47.105 27.192 C 34.128 40.315, 26.027 49.258, 26.017 50.474 C 26.006 51.721, 33.676 60.123, 46.857 73.304 C 65.509 91.956, 68.019 94.126, 70.607 93.830 C 73.001 93.557, 73.500 93.035, 73.500 90.803 C 73.500 88.643, 69.781 84.357, 54.797 69.247 L 36.094 50.386 55.080 31.443 C 72.140 14.422, 74.037 12.196, 73.783 9.500 C 73.555 7.083, 72.982 6.440, 70.838 6.192 C 68.544 5.927, 65.259 8.834, 47.105 27.192" stroke="none" fill="#4c64dc" fillRule="evenodd"/></svg>
            </div>
          </div>}
          {freeCourses && <div className={styles.free_courses}>
            <h2 className={styles.courses_title}>{t('Main.FreeCourses')}</h2>
            <Swiper
              modules={[Pagination, Navigation]}
              spaceBetween={15}
              slidesPerView={1}
              pagination={true}
              navigation={{
                nextEl: ".swiper-button-next-free",
                prevEl: ".swiper-button-prev-free",
              }}
              breakpoints={breakpoints}
            >
              <div className={styles.test}>
                {freeCourses && freeCourses.map(course => {
                  return (
                    <SwiperSlide key={course.id}>
                      <CourseCard
                        {...course}
                        key={course.id}
                        handleButtonClick={() => createCourseRequest(course.id)}
                      />
                    </SwiperSlide>
                  )
                })}
              </div>
              <div className="swiper-button-next-free free-right">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 100 100" version="1.1"><path d="" stroke="none" fill="#5064dc" fillRule="evenodd"/><path d="M 26.667 6.667 C 26.300 7.033, 26 8.492, 26 9.907 C 26 11.913, 30.133 16.619, 44.740 31.240 L 63.480 50 44.658 68.841 C 27.841 85.676, 25.872 87.993, 26.168 90.591 C 26.439 92.965, 27.035 93.561, 29.409 93.832 C 32.017 94.129, 34.479 92.006, 53.159 73.341 C 69.249 57.265, 74 51.944, 74 50 C 74 46.301, 33.614 6, 29.908 6 C 28.492 6, 27.033 6.300, 26.667 6.667" stroke="none" fill="#4c64dc" fillRule="evenodd"/></svg>
              </div>
              <div className="swiper-button-prev-free free-left">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 100 100" version="1.1"><path d="M 43.489 30.250 L 26.500 47.500 43.750 30.511 C 53.237 21.167, 61 13.405, 61 13.261 C 61 12.548, 59.273 14.224, 43.489 30.250 M 56 70.500 C 65.613 80.125, 73.704 88, 73.979 88 C 74.254 88, 66.613 80.125, 57 70.500 C 47.387 60.875, 39.296 53, 39.021 53 C 38.746 53, 46.387 60.875, 56 70.500" stroke="none" fill="#4c5cdc" fillRule="evenodd"/><path d="M 47.105 27.192 C 34.128 40.315, 26.027 49.258, 26.017 50.474 C 26.006 51.721, 33.676 60.123, 46.857 73.304 C 65.509 91.956, 68.019 94.126, 70.607 93.830 C 73.001 93.557, 73.500 93.035, 73.500 90.803 C 73.500 88.643, 69.781 84.357, 54.797 69.247 L 36.094 50.386 55.080 31.443 C 72.140 14.422, 74.037 12.196, 73.783 9.500 C 73.555 7.083, 72.982 6.440, 70.838 6.192 C 68.544 5.927, 65.259 8.834, 47.105 27.192" stroke="none" fill="#4c64dc" fillRule="evenodd"/></svg>
              </div>
            </Swiper>
          </div>}
          <div className={styles.business} ref={businessRef}>
            <Row>
              <Col xs={12} md={5}>
                <h2>{t('Main.BusinessTitle')}</h2>
                <div className={styles.text}>
                  {t('Main.BusinessText')}
                </div>
                <div className={styles.highlight}>
                  {t('Main.Business5Percent')}
                </div>
                <div className={styles.highlight}>
                  {t('Main.Business10Percent')}
                </div>
                <div className={styles.highlight}>
                  {t('Main.Business15Percent')}
                </div>
              </Col>
              <Col xs={12} md={7}>
                <Form className={styles.form} onSubmit={(e) => {
                  e.preventDefault();
                  submitForm(values);
                }}>
                  <Input
                    label='Email'
                    name='email'
                    placeholder={t('Main.EmailPlaceholder')}
                    type='email'
                    required
                    onChange={handleChange}
                    onBlur={validateEmail}
                    value={values.email}
                    error={emailError}
                    errorText={t('Main.EmailInvalid')}
                  />
                  <Input
                    label={t('User.Phone')}
                    name='phone'
                    placeholder={t('Main.PhonePlaceholder')}
                    required
                    onChange={handleChange}
                    onBlur={validatePhone}
                    value={values.phone}
                    error={phoneError}
                    errorText={t('Main.PhoneInvalid')}
                  />
                  <Input
                    label={t('SignUp.Name')}
                    name='name'
                    placeholder={t('Main.NamePlaceholder')}
                    required
                    onChange={handleChange}
                    value={values.name}
                  />
                  <Input
                    label={t('User.Company')}
                    name='companyName'
                    placeholder={t('Main.CompanyPlaceholder')}
                    required
                    onChange={handleChange}
                    value={values.companyName}
                  />
                  <Button
                    modifier='style_dark-blue'
                    type='submit'
                    className={styles.button}
                    disabled={disableSubmit}
                  >
                    {t('Main.Submit')}
                  </Button>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
      <ModalComponent
        modalIsOpen={modalIsOpen}
        handleOkModal={handleCloseModal}
        handleCloseModal={handleCloseModal}
        modalTitle={t('CourseCard.SuccessCourseRequest')} 
      />
      <ModalComponent
        modalIsOpen={modalSuccessRequestIsOpen}
        handleOkModal={handleCloseModal}
        handleCloseModal={handleCloseModal}
        modalTitle={t('Main.SuccessRequest')} 
      />
    </Main>
  )
}

export default HomePage


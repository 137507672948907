import { Container, Main, Button, Icons, LinkComponent, ModalComponent, CustomToast, Loader } from '../../components'
import { Badge } from 'react-bootstrap'
import { UserContext, AuthContext } from '../../contexts'
import { useContext, useState, useEffect } from 'react'
import styles from './styles.module.css'
import { useParams } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import Modules from './modules'
import api from '../../api'
import { useTranslation } from 'react-i18next'

const SingleCourse = ({ history }) => {
  const authContext = useContext(AuthContext)
  const userContext = useContext(UserContext)
  const [ course, setCourse ] = useState({})
  const [ modalIsOpen, setModalIsOpen ] = useState(false)
  const [ apiError, setApiError ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(true)

  const { id } = useParams()
  const { t, i18n } = useTranslation()

  const getCourse = async (id) => {
    try {
        const userLanguage = i18n.language;
      const response = await api.getCourse({ id, userLanguage })
      setCourse(response)
    }
    catch (error) {
      console.error(error)
    }
  }

  const fetchData = async () => {
    setIsLoading(true);
    await getCourse(id);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [i18n.language, ])

  const createCourseRequest = async () => {
    if (authContext === true) {
      try {
        const response = await api.createCourseRequest({ course: id })
        setModalIsOpen(true);
        getCourse(id)
      }
      catch (error) {
        console.error(error)
        setApiError(true)
      }
    }
    else {
      history.push('/signin');
    }
  }

  const handleCloseModal = () => {
    setModalIsOpen(false)
  }

  let completionTimetext = ''
  let sum_completion_time = Number(course.sum_completion_time)
  if (0 <= sum_completion_time && sum_completion_time < 60) {
    completionTimetext = `${sum_completion_time} ${t('Time.Sec')}.`
  }
  else if (60 <= sum_completion_time && sum_completion_time < 3600) {
    completionTimetext = `${(sum_completion_time / 60).toFixed(0)} ${t('Time.Minute')}.`
  }
  else {
    completionTimetext = `${(sum_completion_time / 3600).toFixed(1)} ${t('Time.Hour')}.`
  }
  
  return (isLoading ? <Loader /> :
    <Main>
      <CustomToast
        headerText={t('Errors.Error')}
        bodyText={t('Errors.CommonError')}
        onClose={() => setApiError(false)}
        show={apiError}
        delay={3000}
      />
      <Container>
        <MetaTags>
          <title>{course.name}</title>
          <meta name="description" content={`Продуктовый помощник - ${course.name}`} />
          <meta property="og:title" content={course.name} />
        </MetaTags>
        <div className={styles['single-card']}>
          <img src={course.image} alt={course.name} className={styles["single-card__image"]} />
          <div className={styles["single-card__info"]}>
            <div>
              <LinkComponent
                className={styles['single-card__title']}
                href={`/courses/${id}`}
                title={course.name}
              />
              <div className={styles['single-card__text']}>
                {course.description}
              </div>
              <div className={styles['single-card__time']}>
                <Icons.ClockIcon /> {completionTimetext}
              </div>
              {course?.is_new && <Badge className={styles['single-card__new']} bg='info'>{t('Status.New')}</Badge>}
              {course?.cost && !course?.is_free === true
                ?
                  <div className={styles['single-card__text']}>
                    <span className={styles['single-card__highlight']}>{t('CourseCard.Price')}: </span>{course.cost + ' ₸'}
                  </div>
                :
                  <div className={styles['single-card__text']}>
                    <span className={styles['single-card__highlight']}>{t('CourseCard.Price')}: </span>{t('CourseCard.Free')}
                  </div>
              }
              <div className={styles['single-card__text']}>
                <span className={styles['single-card__highlight']}>{t('CourseCard.Certificate')}: </span>{course.with_certificate ? t('CourseCard.IsGiven') : t('CourseCard.IsNotGiven')}
              </div>
            </div>
            <div className={styles['single-card__buttons']}>
              {!userContext?.is_on_contract && 
                <Button
                  className={styles['single-card__add']}
                  clickHandler={createCourseRequest}
                  disabled={course.has_new_or_review_course_request}
                >
                  {course.has_new_or_review_course_request ? t('CourseCard.OnReview') : t('CourseCard.CreateRequest')}
                </Button>
              }
            </div>
            <div className={styles['single-card__modules-title']}>
              {t('CourseCard.Modules')}
            </div>
            {course?.modules && 
              <Modules
                modules={course.modules}
              />
            }
          </div>
      </div>
      </Container>
      <ModalComponent
        modalIsOpen={modalIsOpen}
        handleOkModal={handleCloseModal}
        handleCloseModal={handleCloseModal}
        modalTitle={t('CourseCard.SuccessCourseRequest')} 
      />
    </Main>
  )
}

export default SingleCourse


import styles from './style.module.css'
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import ShortLessonCard from './short-lesson-card';
import Badge from 'react-bootstrap/Badge';
import { useTranslation } from "react-i18next";

const ModuleCard = (
    {
        study_session_id,
        module,
        is_done,
        completed_video_lessons_ids,
        completed_external_document_lessons_ids,
        completed_internal_document_lessons_ids,
        completed_practical_tasks_ids,
        history
    }
) => {

  const { t } = useTranslation();

  return (
    <div className={styles.session_container}>
      <div className={styles.module_title}>
        {module.name} {is_done && <Badge bg='success'>{t('Lesson.Completed')}</Badge>}
      </div>
      <div className={styles.module_description}>
        {module.description}
      </div>
        <Accordion alwaysOpen className={styles.accordion}>
            <Accordion.Item eventKey='video_lessons'>
              <Accordion.Header>{t('Lesson.VideoLessons')}</Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                  {module.video_lessons.map((video_lesson) => {
                    const url = 'video_lessons'
                    return (
                      <ListGroup.Item>
                        <ShortLessonCard
                          module={module}
                          lesson={video_lesson}
                          url={url}
                          study_session_id={study_session_id}
                          is_done={completed_video_lessons_ids.includes(video_lesson.id)}
                          history={history}
                        />
                      </ListGroup.Item>
                    )
                  })}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey='external_document_lessons'>
              <Accordion.Header>{t('Lesson.ExternalLessons')}</Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                  {module.external_document_lessons.map((external_document_lesson) => {
                    const url = 'external_document_lessons'
                    return (
                      <ListGroup.Item>
                        <ShortLessonCard
                          module={module}
                          lesson={external_document_lesson}
                          url={url}
                          study_session_id={study_session_id}
                          is_done={completed_external_document_lessons_ids.includes(external_document_lesson.id)}
                          history={history}
                        />
                      </ListGroup.Item>
                    )
                  })}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey='internal_document_lessons'>
              <Accordion.Header>{t('Lesson.InternalLessons')}</Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                  {module.internal_document_lessons.map((internal_document_lesson) => {
                    const url = 'internal_document_lessons'
                    return (
                      <ListGroup.Item>
                        <ShortLessonCard
                          module={module}
                          lesson={internal_document_lesson}
                          url={url}
                          study_session_id={study_session_id}
                          is_done={completed_internal_document_lessons_ids.includes(internal_document_lesson.id)}
                          history={history}
                        />
                      </ListGroup.Item>
                    )
                  })}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey='practical tasks'>
              <Accordion.Header>{t('Lesson.PracticalTasks')}</Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                  {module.practical_tasks.map((practical_task) => {
                    const url = 'practical_tasks'
                    return (
                      <ListGroup.Item>
                        <ShortLessonCard
                          module={module}
                          lesson={practical_task}
                          url={url}
                          study_session_id={study_session_id}
                          is_done={completed_practical_tasks_ids.includes(practical_task.id)}
                          history={history}
                        />
                      </ListGroup.Item>
                    )
                  })}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
  )
}

export default ModuleCard
import { Container, Main, Title, ModuleCard, ExamsCardList, ModalComponent, CustomToast, Loader } from '../../components'
import { ProgressBar, Col, Row, ListGroup, Tab, Badge, Breadcrumb, Card } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { useParams, useLocation } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import './list_group.css'
import api from '../../api'
import { useTranslation } from 'react-i18next'

const StudySession = ({ history }) => {

  const [ studySession, setStudySession ] = useState(null)
  const [ exams, setExams ] = useState(null)
  const [ modalIsOpen, setModalIsOpen ] = useState(false)
  const [ clickId, setClickId ] = useState(null)
  const [ activeTab, setActiveTab ] = useState('');
  const [ apiError, setApiError ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(true);

  const { t } = useTranslation();
  const { id } = useParams()
  const location = useLocation();

  const getTab = () => {
    const hash = location.hash;
    if (hash) {
      setActiveTab(hash);
    } else {
      setActiveTab(`#${studySession?.course?.modules[0]?.id}`);
    }
  }

  const getStudySession = async (id) => {
    try {
      const response = await api.getStudySession({ id })
      setStudySession(response)
    }
    catch (error) {
      console.error(error)
    }
  }

  const getExams = async (study_session_id) => {
    try {
      const response = await api.getExams({ study_session_id })
      setExams(response.results)
    }
    catch (error) {
      console.error(error)
    }
  }

  const startExam = async(id) => {
    try {
      const response = await api.startExam({ id })
      history.push(`/exams/${id}`)
    }
    catch (error) {
      console.error(error)
      setApiError(true)
    }
  }

  const fetchData = async () => {
    setIsLoading(true);
    await getStudySession(id);
    await getExams(id);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    getTab();
  }, [location])
  
  return (isLoading ? <Loader /> :
    <Main>
      <CustomToast
        headerText={t('Errors.Error')}
        bodyText={t('Errors.CommonError')}
        onClose={() => setApiError(false)}
        show={apiError}
        delay={3000}
      />
      {studySession &&
        <Container>
          <MetaTags>
            <title>{studySession.course.name}</title>
            <meta name="description" content={`Учебная платформа STIA - ${studySession.course.name}`} />
            <meta property="og:title" content={studySession.course.name} />
          </MetaTags>
          <div className={styles.header}>
            <Title
              title={studySession.course.name}
              badge={studySession.is_done && <Badge bg='success'>{t('StudySessionCard.Completed')}</Badge>}
            />
            <Breadcrumb>
              <Breadcrumb.Item href={'/profile/study_sessions/'}>{t('Header.Profile')}</Breadcrumb.Item>
              <Breadcrumb.Item active>{studySession.course.name}</Breadcrumb.Item>
            </Breadcrumb>
            {studySession.is_done === true && exams !== null &&
              <Card className={styles.exams}>
                <Card.Header>
                  <h2>{t('StudySessionCard.AvailableExams')}</h2>
                  <div className={styles.exams_text}>
                    {t('StudySessionCard.Congrats')}
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className={styles.exams_list}>
                    <h3>{t('StudySessionCard.TestExams')}</h3>
                    <ExamsCardList
                      study_sesion={studySession}
                      exams={exams.filter(exam => exam.type == 'TEST')}
                      setModalIsOpen={setModalIsOpen}
                      setClickId={setClickId}
                      history={history}
                    />
                  </div>
                  <div className={styles.exams_list}>
                    <h3>{t('StudySessionCard.RealExams')}</h3>
                    <ExamsCardList
                      study_sesion={studySession}
                      exams={exams.filter(exam => exam.type == 'REAL')}
                      setModalIsOpen={setModalIsOpen}
                      setClickId={setClickId}
                      history={history}
                    />
                  </div>
                  <ModalComponent
                    modalIsOpen={modalIsOpen}
                    modalTitle={t('StudySessionCard.Start')}
                    handleOkModal={() => startExam(clickId)}
                    handleCloseModal={() => setModalIsOpen(false)}
                  />
                </Card.Body>
              </Card>
            }
            <h2 className={styles.progress_text}>{t('StudySessionCard.Progress')}</h2>
            <ProgressBar
              variant='success'
              now={studySession.progress}
              label={t('StudySessionCard.ProgressPercent', {percent: studySession.progress})}
              className={styles.progress_bar}
            />
          </div>
          <div className={styles.study_session}>
            <Tab.Container id="list-group-tabs-example" defaultActiveKey={`#${studySession?.course?.modules[0].id}`}>
              <Row>
                <Col sm={4}>
                  <ListGroup>
                    {studySession.course.modules.map(_module => {
                      return (
                        <ListGroup.Item
                          action
                          href={`#${_module.id}`}
                          className={styles.module_item}
                          active={`#${_module.id}` === activeTab}
                        >
                          {_module.name + ' '}
                          {studySession.completed_modules_ids.includes(_module.id) ? <Badge bg='success'>✓</Badge> : ''}
                        </ListGroup.Item>
                      )
                    })}
                  </ListGroup>
                </Col>
                <Col sm={8}>
                  <Tab.Content>
                    {studySession.course.modules.map(_module => {
                      return (
                        <Tab.Pane eventKey={`#${_module.id}`} active={`#${_module.id}` === activeTab}>
                          <ModuleCard
                            module={_module}
                            study_session_id={studySession.id}
                            is_done={studySession.completed_modules_ids.includes(_module.id)}
                            completed_video_lessons_ids={studySession.completed_video_lessons_ids}
                            completed_external_document_lessons_ids={studySession.completed_external_document_lessons_ids}
                            completed_internal_document_lessons_ids={studySession.completed_internal_document_lessons_ids}
                            completed_practical_tasks_ids={studySession.completed_practical_tasks_ids}
                            history={history}
                          />
                        </Tab.Pane>
                      )
                    })}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Container>
      }
    </Main>
  )
}

export default StudySession


import Accordion from 'react-bootstrap/Accordion';
import styles from './styles.module.css'
import Lessons from '../lessons';
import PracticalTasks from '../practical-tasks';
import './accordion.css'
import { useTranslation } from 'react-i18next';

function Modules(props) {
  const { modules } = props
  const { t } = useTranslation()
  return (
    <Accordion alwaysOpen className={styles.accordion}>
      {modules.map((_module) => (
        <Accordion.Item eventKey={_module.id}>
          <Accordion.Header>{_module.name}</Accordion.Header>
          <Accordion.Body>
            {_module.description}
            <div>
              <div className={styles['lesson-title']}>
                {t('Lesson.Lessons')}
              </div>
              <Lessons lessons={[..._module.video_lessons, ..._module.external_document_lessons, ..._module.internal_document_lessons]} />
            </div>
            {_module?.practical_tasks.length > 0 &&
              <div>
                <div className={styles['lesson-title']}>
                  {t('Lesson.PracticalTasks')}
                </div>
                <PracticalTasks practical_tasks={_module?.practical_tasks} />
              </div>
            }
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

export default Modules;
import { TailSpin } from 'react-loader-spinner';
import styles from './styles.module.css'

const Loader = () => {
  return (
    <div className={styles['loader-overlay']}>
      <TailSpin color="#4A61DD" height={80} width={80} />
    </div>
  );
};

export default Loader
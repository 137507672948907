import { Col, Row } from 'react-bootstrap';
import { ExamCard } from '..';
import { useTranslation } from 'react-i18next';

const ExamsCardList = ({
    exams,
    study_sesion,
    setModalIsOpen,
    setClickId,
    history
  }) => {

    const { t } = useTranslation();
  
    return (
      <Row>
        {exams.map((exam) => {
          return (
            <Col>
              <ExamCard
                id={exam.id}
                name={t('ExamCard.CourseExam', {courseName: study_sesion.course.name})}
                type={exam.type}
                status={exam.status}
                count_of_available_attempts={exam.count_of_available_attempts}
                duration={exam.duration}
                is_passed={exam.is_passed}
                percent_of_right_answers={exam.percent_of_right_answers}
                pass_percent={exam.pass_percent}
                finish_dt={exam.finish_dt}
                setModalIsOpen={setModalIsOpen}
                setClickId={setClickId}
                history={history}
              />
            </Col>
          )
        })}
      </Row>
    )
  }
  
  export default ExamsCardList
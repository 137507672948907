import { Container, LinkComponent, Title, Main } from '../../components'
import styles from './styles.module.css'
import MetaTags from 'react-meta-tags'
import { useTranslation } from 'react-i18next'

const ConfirmSetNewPassword = () => {
  const { t } = useTranslation();
  return <Main>
    <Container>
      <MetaTags>
        <title>{t('ConfirmSetNewPassword.ConfirmSetNewPassword')}</title>
        <meta name="description" content="Учебная платформа STIA - подтверждение установки нового пароля" />
        <meta property="og:title" content={t('ConfirmSetNewPassword.ConfirmSetNewPassword')} />
      </MetaTags>
      <Title title={t('ConfirmSetNewPassword.ConfirmSetNewPassword')} />
      <div className={styles.text}>
        {t('ConfirmSetNewPassword.SuccessConfirmSetNewPassword')}
      </div>
      <LinkComponent
        href='/signin'
        title={t('Header.SignIn')}
        className={styles.menuButton}
      />
    </Container>
  </Main>
}

export default ConfirmSetNewPassword

import { Container, Input, Title, Main, Form, Button, LinkComponent } from '../../components'
import api from '../../api'
import styles from './styles.module.css'
import { useFormWithValidation } from '../../utils'
import { AuthContext } from '../../contexts'
import { Redirect } from 'react-router-dom'
import { useContext, useState } from 'react'
import MetaTags from 'react-meta-tags'
import { useTranslation } from 'react-i18next'

const SignIn = ({ setLoggedIn, setUser, history }) => {
  const { values, handleChange, errors, isValid, resetForm } = useFormWithValidation()
  const authContext = useContext(AuthContext)

  const [ formErrors, setFormErrors ] = useState({})
  const { t, i18n } = useTranslation()

  const signIn = async ({ email, password }) => {
    try {
      let emailLowerCase = email.toLowerCase()
      const response = await api.signin({ email: emailLowerCase, password });
      if (response.auth_token) {
        localStorage.setItem('token', response.auth_token);
        setFormErrors({});
        const userData = await api.getUserData();
        setUser(userData);
        i18n.changeLanguage(userData.language);
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    } catch (errors) {
      setFormErrors(errors);
      setLoggedIn(false);
      history.push('/signin');
    }
  };

  return <Main>
    {authContext && <Redirect to='/recipes' />}
    <Container>
      <MetaTags>
        <title>{t('Header.SignIn')}</title>
        <meta name="description" content="Продуктовый помощник" />
        <meta property="og:title" content={t('Header.SignIn')} />
      </MetaTags>
      <Title title={t('Header.SignIn')} />
      <Form
        className={styles.form}
        onSubmit={e => {
          e.preventDefault()
          signIn(values)
        }}
      >
        {Object.keys(formErrors).length > 0 && 
          <div className={styles.errorMessage}>
              {t('Errors.SignInError')}
          </div>
        }
        <Input
          required
          label='Email'
          name='email'
          onChange={handleChange}
        />
        <Input
          required
          label={t('ChangePassword.Password')}
          type='password'
          name='password'
          onChange={handleChange}
        />
        <Button
          modifier='style_dark-blue'
          disabled={!isValid}
          type='submit'
          className={styles.button}
        >
          {t('Header.SignIn')}
        </Button>
      </Form>
      <LinkComponent
        href='reset_password/'
        title={t('ChangePassword.ForgotPassword')}
      >
      </LinkComponent>
    </Container>
  </Main>
}

export default SignIn

import { Container, Main, Title, ExamQuestion, Loader, Button } from '../../components'
import { chunk } from '../../utils'
import { Col, Row, ListGroup, Tab, Badge, Breadcrumb } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { useParams } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import api from '../../api'
import { useTranslation } from 'react-i18next'

const ExamResult = ({ history }) => {

  const [ exam, setExam ] = useState(null)
  const [ questionIndex, setQuestionIndex ] = useState(0);
  const [ isLoading, setIsLoading ] = useState(true)

  const { t } = useTranslation()
  const { id } = useParams()

  const getExamResult = async (id) => {
    try {
      const response = await api.getExamResult({ id })
      setExam(response)
    }
    catch (error) {
      console.error(error)
    }
  }

  const fetchData = async () => {
    setIsLoading(true);
    await getExamResult(id);
    setIsLoading(false);
  }

  const prevQuestionHandler = () => {
    if (questionIndex > 0) {
      setQuestionIndex(questionIndex - 1);
    }
  };

  const studySessionClickHandler = () => {
    history.push(`/study_sessions/${exam.study_session.id}`)
  }
  
  const nextQuestionHandler = () => {
    if (questionIndex < exam.questions.length - 1) {
      setQuestionIndex(questionIndex + 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, [])
  
  return (isLoading ? <Loader /> :
    <Main>
      {exam &&
        <Container>
          <MetaTags>
            <title>{t('ExamCard.CourseExam', {courseName: exam.study_session.course.name})}</title>
            <meta name="description" content={`Учебная платформа STIA - Экзамен по курсу ${exam.study_session.course.name}`} />
            <meta property="og:title" content={t('ExamCard.CourseExam', {courseName: exam.study_session.course.name})} />
          </MetaTags>
          <div className={styles.header}>
            <Title
              title={t('ExamCard.CourseExam', {courseName: exam.study_session.course.name})}
            />
            <Breadcrumb>
              <Breadcrumb.Item href={'/profile'}>{t('Header.Profile')}</Breadcrumb.Item>
              <Breadcrumb.Item href={`/study_sessions/${exam.study_session.id}`}>{t('CourseCard.Course')}</Breadcrumb.Item>
              <Breadcrumb.Item active>{t('ExamCard.CourseExam', {courseName: exam.study_session.course.name})}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className={styles.exam_card}>
            <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
              <Row>
                <Col sm={8}>
                  <Row className={styles.question}>
                    <Col sm={12}>
                      <Tab.Content>
                        {exam.questions.map(question => {
                          return (
                            <Tab.Pane eventKey={`#${question.id}`} active={questionIndex === exam.questions.indexOf(question)}>
                              <ExamQuestion
                                exam_id={exam.id}
                                question={question}
                                isResult={true}
                              />
                            </Tab.Pane>
                          )
                        })}
                      </Tab.Content>
                    </Col>
                    <Col sm={12}>
                      <div className={styles.navigation}>
                        <Button
                          clickHandler={prevQuestionHandler}
                          disabled={questionIndex === 0}
                        >
                          {t('ExamCard.ToPrevQuestion')}
                        </Button>
                        <Button
                          clickHandler={studySessionClickHandler}
                        >
                          {t('Lesson.ToStudySession')}
                        </Button>
                        <Button
                          clickHandler={nextQuestionHandler}
                          disabled={questionIndex === exam.questions.length - 1}
                        >
                          {t('ExamCard.ToNextQuestion')}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col sm={4}>
                  <Row className={styles.question_selector}>
                    {chunk(exam.questions, 10).map((row, rowIndex) => (
                      <Col sm={12} key={rowIndex}>
                        <ListGroup horizontal className={styles.question_list_group}>
                          {row.map((question, index) => (
                            <ListGroup.Item
                              onClick={() => setQuestionIndex(exam.questions.findIndex((item) => item.id === question.id))}
                              action
                              href={`#${question.id}`}
                              className={styles.module_item}
                              key={index}
                              active={questionIndex === exam.questions.indexOf(question)}
                            >
                              {question.user_answer &&
                              question.correct_answer.id === question.user_answer.answer ? (
                                <Badge bg='success'>{rowIndex * 10 + index + 1 + ' '}</Badge>
                              ) : (
                                <Badge bg='danger'>{rowIndex * 10 + index + 1 + ' '}</Badge>
                              )}
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Container>
      }
    </Main>
  )
}

export default ExamResult


import styles from './style.module.css'
import { LinkComponent } from '../index'
import { useContext } from 'react'
import { AuthContext } from '../../contexts'
import { useTranslation } from 'react-i18next'

const StudySessionCard = ({
  id,
  course,
  completed_modules_count,
  progress,
  is_done,
}) => {
  const authContext = useContext(AuthContext)
  const { t } = useTranslation();

  return <div className={styles.card}>
      <LinkComponent
        className={styles.card__title}
        href={`/study_sessions/${id}`}
        title={<div className={styles.card__image} style={{ backgroundImage: `url(${ course.image })` }} />}
      />
      <div className={styles.card__body}>
        <LinkComponent
          className={styles.card__title}
          href={`/study_sessions/${id}`}
          title={course.name}
        />
        <div className={styles.card__text}>
          {course.description}
        </div>
        <div className={styles.card__text}>
          <span className={styles.card__highlight}>{t('StudySessionCard.ModulesCompleted')}: </span>{completed_modules_count}
        </div>
        <div className={styles.card__text}>
          <span className={styles.card__highlight}>{t('StudySessionCard.Progress')}: </span>{progress} %
        </div>
        <div className={styles.card__text}>
          <span className={styles.card__highlight}>{t('StudySessionCard.Completed')}: </span>{is_done ? t('Yes') : t('No')}
        </div>
      </div>
      
      <div className={styles.card__footer}>
          <div className={styles.card__author}>
            <LinkComponent
              href={`/courses/${course.id}`}
              title={t('CourseCard.MoreAboutCourse')}
              className={styles.card__link}
            />
          </div>
      </div>
  </div>
}

export default StudySessionCard
import styles from './style.module.css'
import { Container, LinkComponent } from '../index'
import { Row, Col } from 'react-bootstrap'

const Footer = () => {
  return <footer className={styles.footer}>
      <Container className={styles.footer__container}>
        <Row>
          <Col xs={12} md={3} className={styles.footer__block}>
          <a href='https://stia.kz'className={styles.footer__link}>STIA.KZ</a>
          </Col>
          <Col xs={12} md={9} className={styles.footer__block}>
            <p className={styles.footer__link}>Email: info@stia.kz</p>
            <p className={styles.footer__link}>Tel: +7-717-224-55-22</p>
          </Col>
        </Row>
      </Container>
  </footer>
}

export default Footer

import { Container, Main, Title, ModalComponent, ExamQuestion, Button, CustomToast, Loader } from '../../components'
import { chunk } from '../../utils'
import { Col, Row, ListGroup, Tab, Badge, Breadcrumb, ProgressBar } from 'react-bootstrap'
import Countdown from 'react-countdown'
import { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { useParams } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import api from '../../api'
import { useTranslation } from 'react-i18next'

const Exam = ({ history }) => {

  const [ exam, setExam ] = useState(null)
  const [ questionIndex, setQuestionIndex ] = useState(0);
  const [ modalIsOpen, setModalIsOpen ] = useState(false)
  const [ apiError, setApiError ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);

  const { t } = useTranslation();
  const { id } = useParams();

  const getExam = async (id) => {
    try {
      const response = await api.getExam({ id })
      setExam(response)
    }
    catch (error) {
      console.error(error)
    }
  }

  const submitAnswer = async (exam, question, answer, user_answer) => {
    try {
      const response = await api.submitAnswer({ exam, question, answer, user_answer })
      getExam(id);
    }
    catch (error) {
      console.error(error)
      setApiError(true);
    }
  }

  const finishExam = async (id) => {
    try {
      const response = await api.finishExam({ id });
      setModalIsOpen(false);
      if (exam.type == 'TEST') {
        history.push(`/exams/${id}/result`)
      }
      else {
        history.push(`/study_sessions/${exam.study_session.id}`)
      }
    }
    catch (error) {
      console.error(error)
      setApiError(true);
    }
  }

  const prevQuestionHandler = () => {
    if (questionIndex > 0) {
      setQuestionIndex(questionIndex - 1);
    }
  };

  const studySessionClickHandler = () => {
    history.push(`/study_sessions/${exam.study_session.id}`)
  }
  
  const nextQuestionHandler = () => {
    if (questionIndex < exam.questions.length - 1) {
      setQuestionIndex(questionIndex + 1);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    await getExam(id);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [])
  
  return (isLoading ? <Loader /> :
    <Main>
      <CustomToast
        headerText={t('Errors.Error')}
        bodyText={t('Errors.CommonError')}
        onClose={() => setApiError(false)}
        show={apiError}
        delay={3000}
      />
      {exam &&
        <Container>
          <MetaTags>
            <title>{t('ExamCard.CourseExam', {courseName: exam.study_session.course.name})}</title>
            <meta name="description" content={`Учебная платформа STIA - Экзамен по курсу ${exam.study_session.course.name}`} />
            <meta property="og:title" content={t('ExamCard.CourseExam', {courseName: exam.study_session.course.name})} />
          </MetaTags>
          <div className={styles.header}>
            <Title
              title={t('ExamCard.CourseExam', {courseName: exam.study_session.course.name})}
            />
            <Breadcrumb>
              <Breadcrumb.Item href={'/profile'}>{t('Header.Profile')}</Breadcrumb.Item>
              <Breadcrumb.Item href={`/study_sessions/${exam.study_session.id}`}>{t('CourseCard.Course')}</Breadcrumb.Item>
              <Breadcrumb.Item active>{t('ExamCard.CourseExam', {courseName: exam.study_session.course.name})}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row className={styles.finish}>
            <Col xs={2}>
              <div className={styles.countdown}>
                <Countdown
                  date={new Date(exam.projected_finish_dt)}
                  onComplete={() => finishExam(id)}
                />
              </div>
            </Col>
            <Col xs={1}>
              <Button clickHandler={() => setModalIsOpen(true)}>{t('ExamCard.FinishExam')}</Button>
            </Col>
          </Row>
          <h2 className={styles.progress_text}>{t('StudySessionCard.Progress')}</h2>
            <ProgressBar
              variant='success'
              now={exam.progress}
              label={t('StudySessionCard.ProgressPercent', {percent: exam.progress})}
              className={styles.progress_bar}
            />
          <ModalComponent
            modalIsOpen={modalIsOpen}
            modalTitle={t('ExamCard.ConfirmFinishExam', {amount: exam.questions.filter(question => question.user_answer !== null).length, totalAmount: exam.questions.length})}
            handleOkModal={() => finishExam(id)}
            handleCloseModal={() => setModalIsOpen(false)}
          />
          <div className={styles.exam_card}>
            <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
              <Row>
                <Col sm={8}>
                  <Row>
                    <Col sm={12}>
                      <Tab.Content>
                        {exam.questions.map(question => {
                          return (
                            <Tab.Pane eventKey={`#${question.id}`} active={questionIndex === exam.questions.indexOf(question)}>
                              <ExamQuestion
                                examId={exam.id}
                                question={question}
                                submitHandler={submitAnswer}
                                isResult={false}
                              />
                            </Tab.Pane>
                          )
                        })}
                      </Tab.Content>
                    </Col>
                    <Col sm={12}>
                      <div className={styles.navigation}>
                        <Button
                          clickHandler={prevQuestionHandler}
                          disabled={questionIndex === 0}
                        >
                          {t('ExamCard.ToPrevQuestion')}
                        </Button>
                        <Button
                          clickHandler={studySessionClickHandler}
                        >
                          {t('Lesson.ToStudySession')}
                        </Button>
                        <Button
                          clickHandler={nextQuestionHandler}
                          disabled={questionIndex === exam.questions.length - 1}
                        >
                          {t('ExamCard.ToNextQuestion')}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col sm={4}>
                  <Row className={styles.question_selector}>
                    {chunk(exam.questions, 10).map((row, rowIndex) => (
                      <Col sm={8} key={rowIndex}>
                        <ListGroup horizontal className={styles.question_list_group}>
                          {row.map((question, index) => (
                            <ListGroup.Item
                              onClick={() => setQuestionIndex(exam.questions.findIndex((item) => item.id === question.id))}
                              href={`#${question.id}`}
                              className={styles.module_item}
                              key={index}
                              active={questionIndex === exam.questions.indexOf(question)}
                            >
                              {question.user_answer
                                ? (<Badge bg='success'>{rowIndex * 10 + index + 1 + ' '}</Badge>)
                                : (<>{rowIndex * 10 + index + 1 + ' '}</>)
                              }
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Container>
      }
    </Main>
  )
}

export default Exam


import React from 'react'
import Modal from 'react-modal'
import Button from '../button'
import styles from './style.module.css'
import cn from 'classnames'
import './modal.css'

Modal.setAppElement('#root')

const ModalComponent = ({ modalIsOpen, handleOkModal, handleCloseModal, modalClassName, modalTitle }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleCloseModal}
      className={cn(styles.Modal, modalClassName)}
    >
      <h3 className={styles.title}>{modalTitle}</h3>
      <Button
        modifier='style_dark-blue'
        type='submit'
        className={styles.button}
        clickHandler={handleOkModal}
      >
        OK
      </Button>
    </Modal>
  )
}

export default ModalComponent